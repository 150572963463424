import { useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';

import { SearchQuery, useSearchQuery } from '../../../../graphql-types';

interface UseSearchResult {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  loading: boolean;
  error: ApolloError | undefined;
  data: SearchQuery | undefined;
}

/**
 * A custom hook that provides debounced search functionality with GraphQL integration.
 *
 * This hook manages the search state and provides debounced search behavior to prevent
 * excessive API calls. It automatically handles the search query execution after the
 * debounce period and when the minimum search length is met.
 *
 * @param debounceMs - Delay in milliseconds before executing search after user input (default: 300ms)
 * @param minLength - Minimum number of characters required before executing search (default: 3)
 *
 * @returns {UseSearchResult} An object containing:
 *   - searchTerm: Current search input value
 *   - setSearchTerm: Function to update search term
 *   - loading: Boolean indicating if search is in progress
 *   - error: Any GraphQL error that occurred
 *   - data: Search results from the GraphQL query
 *
 * @example
 * ```tsx
 * function SearchComponent() {
 *   const {
 *     searchTerm,
 *     setSearchTerm,
 *     loading,
 *     data
 *   } = useDebounceSearch(500, 2);
 *
 *   return (
 *     <input
 *       value={searchTerm}
 *       onChange={(e) => setSearchTerm(e.target.value)}
 *     />
 *   );
 * }
 * ```
 */
export function useDebounceSearch(
  debounceMs = 300,
  minLength = 3,
): UseSearchResult {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedTerm, setDebouncedTerm] = useState('');

  // Simple debounce
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, debounceMs);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, debounceMs]);

  const { data, loading, error } = useSearchQuery({
    variables: {
      needle: debouncedTerm,
      limit: 50,
    },
    skip: debouncedTerm.length < minLength,
  });

  return {
    searchTerm,
    setSearchTerm,
    loading,
    error,
    data,
  };
}
