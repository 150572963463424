import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';
import { useHotkeys } from 'react-hotkeys-hook';
import { FlexRow, Icons } from '@ampeersenergy/ampeers-ui-components';

import { SearchResult } from '../../../graphql-types';
import { EmptyResult } from '../../table/style';
import ErrorMsg from '../../errorMsg';
import { SpinnerDark } from '../../spinner';
import {
  ContractStatus,
  formatMeter,
  pickName,
  formatInvoice,
  shortenString,
  shortFormatContractStatus,
} from '../../../helpers/formatStrings';
import { IconProps } from '../../baseIcon';

import { useDebounceSearch } from './hooks/useDebounceSearch';
import { HighlightMatches } from './highlightMatch';

interface SearchProps {
  setVisibility(isVisible: boolean | ((visible: boolean) => boolean)): void;
  isVisible: boolean;
}

const Relative = styled.div`
  position: relative;
`;

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 800px;
  margin: 100px auto;
  border-radius: 4px;
  padding: 0px;

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__suggestions-list {
    position: absolute;
    background: #ffffff;
    border: 1px solid #ececec;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    z-index: 2;
    width: 100%;
    margin-top: -3px;
    max-height: 500px;
    overflow: scroll;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0px;
    ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
  }

  li {
    border-bottom: 1px solid #eaeaea;

    > div {
      padding: 12px 15px;
    }
    cursor: pointer;

    &:last-child {
      border: none;
    }

    &.react-autosuggest__suggestion--highlighted {
      background: #dbffd4;
    }
  }
`;

const Input = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid #e4e4e4;
  font-size: 18px;
  padding: 18px 12px 18px 50px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  focus: 0;
  outline: 0;
`;

const SearchIconWrapper = styled.div`
  position: absolute;
  top: 14px;
  left: 10px;
  z-index: 1;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  right: -10px;
  top: -6px;
  padding: 20px;
  cursor: pointer;

  &:hover svg {
    fill: ${(props) => props.theme.primaryColor};
  }
`;

const Results = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 450px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
`;

const Result = styled(FlexRow)``;

const ResultIcon = styled.div`
  margin-right: 10px;
`;

const Tipp = styled.div`
  text-align: center;
  padding: 10px 0px;
`;

const Hint = styled.span`
  color: ${(props) => props.theme.palette.textMuted};
  font-size: 12px;
  margin-left: 10px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Key = styled.span`
  display: inline-block;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  font-size: 14px;
  color: #6d6d6d;
  letter-spacing: 0.52px;
  padding: 4px 10px;
  font-weight: 600;
`;

const Additional = styled.span`
  color: #757575;
  display: inline-block;
`;

const LoadingSpinner = styled(FlexRow)`
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const KeyValuePair = styled.span`
  margin-left: 10px;
`;

const getLink = (result: SearchResult) => {
  switch (result.source) {
    case 'project':
      return `/project/${result.identifiers.sourceId}`;
    case 'plant':
      return `/project/${result.identifiers.projectId}/plant/${result.identifiers.sourceId}`;
    case 'contract':
      return `/project/${result.identifiers.projectId}/plant/${result.identifiers.plantId}/tenant/contract/${result.identifiers.sourceId}`;
    case 'customer':
      return `/project/${result.identifiers.projectId}/plant/${result.identifiers.plantId}/tenant/contract/${result.identifiers.contractId}`;
    case 'meter':
      return `/project/${result.identifiers.projectId}/plant/${result.identifiers.plantId}/meter/${result.identifiers.sourceId}`;
    case 'hist':
      return `/project/${result.identifiers.projectId}/plant/${result.identifiers.plantId}/tenant/contract/${result.identifiers.contractId}/accounting/final-settlements`;
    default:
      throw new Error(`Unknonw Result Type: "${result.source}"`);
  }
};

const IconMap: { [key: string]: React.ComponentType<IconProps> } = {
  project: Icons.Project,
  plant: Icons.Plant,
  contract: Icons.Contract,
  meter: Icons.Meter,
  customer: Icons.Customer,
  hist: Icons.Contract,
};

const formatters: { [key: string]: (data: any) => string } = {
  project: (data: SourceProjectPlant) => pickName(data),
  plant: (data: SourceProjectPlant) => pickName(data),
  contract: (_contract: SourceContract) => pickName(_contract?.customer),
  meter: (meter: SourceMeter) => formatMeter(meter),
  customer: (customer: SourceCustomer) => pickName(customer),
  hist: (data: SourceHist) => formatInvoice(data),
};

type SourceCustomer = {
  id: string;
  label: string;
  name: string;
  contract: {
    id: string;
    label: string;
    status: ContractStatus;
  };
};

type SourceContract = {
  id: string;
  label: string;
  status: ContractStatus;
  customer: {
    id: string;
    label: string;
    name: string;
  };
};

type SourceMeter = {
  meterNumber: string;
  melo: string;
  malo: string;
  label: string;
};

type SourceProjectPlant = {
  name: string;
};

type SourceHist = {
  customer: {
    name: string;
  };
};

const Source: React.FC<{
  pairs: { Icon?: (props: IconProps) => JSX.Element; label: string }[];
}> = ({ pairs }) => (
  <>
    {pairs.map(({ Icon, label }) => (
      <KeyValuePair>
        {Icon && <Icon size={20} color="#757575" />}
        {label}
      </KeyValuePair>
    ))}
  </>
);

const formatSource = (
  source: string,
  designation: string,
  obj: SourceContract | SourceCustomer | SourceMeter | SourceHist,
) => {
  switch (source) {
    case 'contract': {
      const {
        customer: { label },
        status,
      } = obj as SourceContract;
      return (
        <Source
          pairs={[
            { label, Icon: Icons.Customer },
            { label: `Status: ${shortFormatContractStatus(status)}` },
          ]}
        />
      );
    }
    case 'customer': {
      const {
        contract: { status, label: contractLabel },
      } = obj as SourceCustomer;
      return (
        <Source
          pairs={[
            { Icon: Icons.Contract, label: contractLabel },
            { label: `Status: ${shortFormatContractStatus(status)}` },
          ]}
        />
      );
    }
    case 'meter': {
      const { label: contractLabel, melo, malo } = obj as SourceMeter;
      const designationKey = (Object.entries(obj).find(
        (o) => o[1] === designation,
      ) ?? [])[0];

      const labels = { melo: `Melo: ${melo}`, malo: `Malo: ${malo}` };
      const label =
        (designationKey && labels[designationKey as keyof typeof labels]) ?? '';

      return (
        <Source
          pairs={[{ Icon: Icons.Contract, label: contractLabel }, { label }]}
        />
      );
    }
    case 'hist': {
      const { customer } = obj as SourceHist;
      return (
        <Source
          pairs={[
            { Icon: Icons.Contract, label: customer.name },
            { label: `Status: ${designation}` },
          ]}
        />
      );
    }
    default:
      return '';
  }
};

const getLabel = (
  source: string,
  obj: SourceContract | SourceCustomer | SourceMeter,
) => {
  if (source === 'contract') {
    const { label: contractLabel } = obj as SourceContract;
    return `(${contractLabel})`;
  }
  if (source === 'customer') {
    const { label: customerLabel } = obj as SourceCustomer;
    return `(${customerLabel})`;
  }
  return '';
};

const formatResultName = (result: SearchResult) => {
  const formatter = formatters[result.source];
  const shortString =
    formatter === undefined
      ? `Unknown: ${result.source}`
      : `${formatter(result.object)} ${getLabel(result.source, result.object)}`;
  return shortenString(shortString, 50);
};

function Search({ setVisibility, isVisible }: SearchProps) {
  const navigate = useNavigate();
  const { searchTerm, setSearchTerm, loading, error, data } = useDebounceSearch(
    300,
    3,
  );
  const isSearching = searchTerm.trim().length >= 3;

  const handleChange = useCallback((event: any) => {
    setSearchTerm(event?.target.value);
  }, []);

  const close = useCallback(() => {
    setVisibility(false);
    setSearchTerm('');
  }, [setSearchTerm, setVisibility]);

  const onSelect = useCallback(
    (_: any, { suggestion }: any) => {
      const href = getLink(suggestion);
      close();
      navigate(href);
    },
    [close],
  );

  useEffect(() => {
    const onKeyDown = ({ key }: any) => {
      if (key === 'Escape') {
        setVisibility(false);
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [searchTerm, setVisibility]);

  const renderInput = useCallback((props: any) => <Input {...props} />, []);

  const getSuggestionValue = useCallback(() => {
    return '';
  }, []);

  const onSuggestionsFetchRequested = useCallback(
    ({ value }: { value: string }) => {
      setSearchTerm(value);
    },
    [setSearchTerm],
  );

  const onSuggestionsClearRequested = useCallback(
    () => setSearchTerm(''),
    [setSearchTerm],
  );

  const renderSuggestion = useCallback((result: SearchResult) => {
    const Icon: any = IconMap[result.source] ?? null;
    return (
      <Result>
        {Icon && (
          <ResultIcon>
            <Icon size={20} color="#404040" />
          </ResultIcon>
        )}
        <HighlightMatches
          text={formatResultName(result)}
          matches={result.matches || []} // Backend needs to provide this
        />
        {['contract', 'customer', 'meter'].includes(result.source) && (
          <Additional>
            {formatSource(result.source, result.designation, result.object)}
          </Additional>
        )}
      </Result>
    );
  }, []);

  const overlayClose = useCallback(
    (event: any) => {
      if (event.currentTarget === event.target) {
        close();
      }
    },
    [close],
  );

  useHotkeys(
    'metakey+f,ctrl+f',
    (event: KeyboardEvent) => {
      event.preventDefault();
      setSearchTerm('');
      setVisibility((v) => !v);
    },
    {},
    [setSearchTerm, setVisibility],
  );

  if (!isVisible) {
    return null;
  }
  return (
    <Relative>
      <Overlay onClick={overlayClose}>
        <Wrapper>
          <Relative>
            <SearchIconWrapper>
              <Icons.Search size={32} />
            </SearchIconWrapper>
            <Autosuggest
              suggestions={data?.search ?? []}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionSelected={onSelect}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={{
                type: 'text',
                placeholder:
                  'Durchsuche Kunden, Verträge, Kundenanlagen, Projekte und Zähler',
                autoFocus: true,
                value: searchTerm,
                onChange: handleChange,
              }}
              renderInputComponent={renderInput}
            />
            <CloseIconWrapper onClick={close}>
              <Icons.Close size={32} />
            </CloseIconWrapper>
          </Relative>
          {!isSearching && (
            <>
              <Hint>Deine Suche muss mindestens aus drei Zeichen bestehen</Hint>
              <Tipp>
                <span role="img" aria-label="bnlubb">
                  🤘
                </span>
                - <Bold>Tipp</Bold>: Die Suche erreichen Sie auch jederzeit mit{' '}
                <Key>STRG</Key> + <Key>F</Key>
              </Tipp>
            </>
          )}
          <Results>
            {data && data.search.length === 0 && (
              <EmptyResult key="no-result">Keine Ergebnisse</EmptyResult>
            )}
            {error && <ErrorMsg error={error} />}
            {loading && (
              <LoadingSpinner>
                <SpinnerDark size={25} />
              </LoadingSpinner>
            )}
          </Results>
        </Wrapper>
      </Overlay>
    </Relative>
  );
}

export default Search;
