/* eslint-disable import/no-cycle */
/* eslint-disable no-console */
import React, { useMemo, useCallback } from 'react';
import { DeepExtractType } from 'ts-deep-extract-types';
import * as yup from 'yup';

import { useHasRole } from '../../../../components/useHasRole';
import {
  ContractDocumentData,
  DeliveryPreference,
  ReadDocumentsDocument,
  ReadDocumentsQuery,
  useGenerateContractDocumentMutation,
  useGenerateContractDocumentPreviewMutation,
} from '../../../../graphql-types';
import {
  documentTypesKeyToI18n,
  translateDocumentType,
} from '../../../../helpers/formatStrings';
import { DocumentsSidebar } from '../../../../components/documentsSidebar';
import { buildDocumentUrl } from '../../../../helpers/buildDocumentUrl';
import type {
  ContractDocument,
  DocumentType,
} from '../../../../components/documents';

import { PartialContractDocumentData } from './crm-board-card-edit';

export function CrmDocumentsSidebar({
  documents,
  customerEmail,
  contractId,
  documentDeliveryMethod,
  contractDocumentData,
  onDelete,
}: {
  documents?: DeepExtractType<ReadDocumentsQuery, ['readDocuments']>;
  customerEmail?: string | null;
  contractId?: string;
  documentDeliveryMethod: string;
  contractDocumentData?: PartialContractDocumentData;
  onDelete: (hash: string) => void;
}) {
  const [generateContractDocumentMutate] =
    useGenerateContractDocumentMutation();

  const [generatePreviewMutate] = useGenerateContractDocumentPreviewMutation();

  const validContractDocumentData =
    validateContractDocumentData(contractDocumentData);
  const canCreateContractDraftLetter = useHasRole(
    'feature_contract_draft_letter',
  );

  const previewMutate = useCallback(
    async (type: string, _contractId: string) => {
      return generatePreviewMutate({
        variables: {
          type,
          contractId: _contractId,
          contractDocumentData: validContractDocumentData,
        },
      });
    },
    [validContractDocumentData, generatePreviewMutate],
  );

  const generateDocumentMutate = useCallback(
    (
      type: string,
      _contractId: string,
      deliveryPreference: DeliveryPreference | null,
    ) => {
      return generateContractDocumentMutate({
        variables: {
          contractId: _contractId,
          type,
          deliveryPreference: deliveryPreference ?? DeliveryPreference.Browser,
          contractDocumentData: validContractDocumentData,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: ReadDocumentsDocument,
            variables: {
              entityId: validContractDocumentData?.cardId,
              entityType: 'crmLead',
            },
          },
        ],
      });
    },
    [validContractDocumentData, generateContractDocumentMutate],
  );

  const shouldShowContractDocumentsInSidebar =
    canCreateContractDraftLetter.hasRole ||
    (documents?.length && documents.length > 0);

  const documentTypes: DocumentType[] = useMemo(() => {
    if (!shouldShowContractDocumentsInSidebar) return [];
    if (!documents || documents.length === 0) {
      return [
        {
          type: documentTypesKeyToI18n.contract_draft_letter,
          creation: {
            disabled: !validContractDocumentData,
            hint: 'Um einen Vertragsentwurf zu erstellen müssen "Name", "Neue Abnahmestelle", "Aktuelle Adresse", "Geplanter Lieferbeginn", "Kundenanlage" und "Tarif" ausgefüllt sein.',
          },
        },
      ];
    }

    const groupedDocuments = createSidebarDocuments(documents).reduce(
      (acc, { parentGroup, ...document }) => {
        if (parentGroup) {
          return {
            ...acc,
            [parentGroup]: [
              ...(acc[parentGroup] ? acc[parentGroup] : []),
              document,
            ],
          };
        }
        return {
          ...acc,
          [document.type]: [
            ...(acc[document.type] ? acc[document.type] : []),
            document,
          ],
        };
      },
      {} as Record<string, SidebarDocuments[]>,
    );

    return Object.keys(groupedDocuments).map((documentType) => ({
      type: documentType,
      documents: groupedDocuments[documentType],
    }));
  }, [
    documents,
    shouldShowContractDocumentsInSidebar,
    validContractDocumentData,
  ]);

  return (
    <DocumentsSidebar
      documentTypes={documentTypes}
      customerEmail={customerEmail!}
      contractId={contractId!}
      documentDeliveryMethod={documentDeliveryMethod}
      previewDocumentMutation={previewMutate}
      generateDocumentMutation={generateDocumentMutate}
      onDelete={(document: ContractDocument) => onDelete(document.hash!)}
    />
  );
}

type SidebarDocuments = ContractDocument & {
  parentGroup?: string | null;
  type: string;
};

function createSidebarDocuments(
  documents: ReadDocumentsQuery['readDocuments'],
): SidebarDocuments[] {
  return documents.map((document) => {
    const {
      fileName,
      fileURL: relativeURL,
      type,
      parentGroup,
      ...rest
    } = document;

    return {
      ...rest,
      type: translateDocumentType(type),
      fileURL: buildDocumentUrl(relativeURL),
      ...(parentGroup && { parentGroup: translateDocumentType(parentGroup) }),
      deletable: true,
    };
  });
}

function validateContractDocumentData(
  contractDocumentData?: PartialContractDocumentData,
): ContractDocumentData | undefined {
  const addressSchema = yup.object({
    streetName: yup.string().required(),
    streetNumber: yup.string().required(),
    zipCode: yup.string().required(),
    city: yup.string().required(),
  });

  const schema = yup.object().shape({
    name: yup.string().required(),
    currentAddress: addressSchema.required(),
    shippingAddress: addressSchema.required(),
    date: yup.date().required(),
    cardId: yup.string().notRequired(),
    aJV: yup.number().notRequired(),
    meterNumber: yup.string().notRequired(),
    reduction: yup.number().notRequired(),
    startOfDelivery: yup.date().required(),
    tariffId: yup.string().required(),
    plantId: yup.string().required(),
  });

  try {
    if (schema.isValidSync(contractDocumentData)) {
      // TODO: Can we do it without type assignment?
      return contractDocumentData as ContractDocumentData;
    }
    schema.validate(contractDocumentData).catch(console.log);
  } catch (e) {
    console.error(`Error validating contract document data`, { e });
  }
  return undefined;
}
