/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import ErrorBoundary from '../errorBoundary';

import { TableStyles, EmptyResult } from './style';

export interface ListProps {
  data: ListData[];
  kind: string;
  isLoading?: boolean;
}

interface ListData {
  id: number | string;
  customerName: string;
}

const WorklistWindow = styled.div`
  width: 80% !important;
  height: 360px !important;
  // margin-left: 50px;
  // float: right;
  // align: right;
  overflow: auto;
  margin-left: auto;
`;

export function List(props: ListProps) {
  const { data, isLoading, kind } = props;
  // useTraceUpdate(props);

  function renderLoadingRows(pageSize: number) {
    const rows = [];

    for (let i = 0; i < pageSize; i++) {
      rows.push(
        <li>
          <ContentLoader height={21} speed={2}>
            <rect x="0" y="0" rx="0" ry="0" width="115" height="21" />
          </ContentLoader>
        </li>,
      );
    }
    return rows;
  }

  return (
    <TableStyles className="">
      <WorklistWindow>
        <ErrorBoundary>
          <div className="wrapper">
            <ul>
              {isLoading
                ? renderLoadingRows(3)
                : props.data.length > 0
                  ? props.data.map((item) => (
                      <li key={item!.id!}>{item.customerName}</li>
                    ))
                  : null}
            </ul>
            {data.length === 0 && !isLoading && (
              <EmptyResult>Keine {kind} gefunden.</EmptyResult>
            )}
          </div>
        </ErrorBoundary>
      </WorklistWindow>
    </TableStyles>
  );
}
