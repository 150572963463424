/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import Papa, { UnparseConfig } from 'papaparse';
import { DateTime } from 'luxon';
import { ApolloClient, DocumentNode } from '@apollo/client';
import { saveAs } from 'file-saver';

import numeral from '../helpers/numeral';
import { client } from '../apollo-client';
import createMeterReadingsBatched from '../queries/createMeterReadingsBatched';
import { transformObject, KeyMap, ValueMap } from '../helpers/transformObject';
import createMetersBatched from '../queries/createMetersBatched';
import createTariffsBatched from '../queries/createTariffsBatched';
import createContractsBatched from '../queries/createContractsBatched';
import prepareUpdateContractsBatched from '../queries/prepareUpdateContractsBatched';
import createBookingsBatched from '../queries/createBookingsBatched';

interface StringObject {
  [key: string]: string;
}

interface ParseResult {
  data: StringObject[];
  meta: any;
}

export interface CSVConvertConfig {
  headers: string[];
  fields: string[];
  fileName: string;
}
export interface CSVConfig {
  mutation: DocumentNode;
  keyMap?: KeyMap;
  valueMap?: ValueMap;
  mutationVariableResult: string;
  mutationVariables?: any;
  dynamicTyping?: boolean;
  valueDelimiters?: { thousands: string; decimal: string };
  validateFn: (values: ParseResult) => any;
}

export const contractsUpdateCsvConfig = (): CSVConfig => {
  return {
    mutation: prepareUpdateContractsBatched,
    mutationVariableResult: 'contracts',
    validateFn: ({ meta }) => {
      const expectedHeaders = [
        'V_VNR',
        'VP_ANREDE',
        'VP_NAME',
        'VP_COMPANY',
        'VP_COMPANY_CO',
        'VP_EMAIL',
        'VP_TELEFON',
        'VP_MOBIL',
        'VP_WERBUNG',
        'VP_KOMMUNIKATION',
        'VP_STR',
        'VP_HSNR',
        'VP_PLZ',
        'VP_ORT',
        'VP_LK',
        'RE_ANREDE_1',
        'RE_NAME_1',
        'RE_ANREDE_2',
        'RE_NAME_2',
        'RE_COMPANY',
        'RE_COMPANY_CO',
        'RE_STR',
        'RE_HSNR',
        'RE_PLZ',
        'RE_ORT',
        'RE_LK',
        'LF_TYP',
        'LF_ANREDE',
        'LF_NAME',
        'LF_COMPANY',
        'LF_COMPANY_CO',
        'LF_STR',
        'LF_HSNR',
        'LF_PLZ',
        'LF_ORT',
        'LF_LK',
        'V_LIEFERBEGINN',
        'V_STATUS',
        'AB_SEPA',
        'AB_KONTOINHABER',
        'AB_IBAN',
        'AB_BIC',
        'AB_REF',
        'AB_UNTERSCHRIFT',
        'AB_ABSCHLAG',
        'AB_BEGINN',
      ];

      const errors: string[] = meta.fields.reduce(
        (acc: string[], cur: string, index: number) => {
          if (cur !== expectedHeaders[index]) {
            return [
              ...acc,
              `Erwartet "${expectedHeaders[index]}" in Spalte ${
                index + 1
              }, Datei enthält jedoch "${cur}"`,
            ];
          }
          return acc;
        },
        [],
      );

      if (errors.length) {
        throw Error(
          `Vertragsliste entspricht nicht dem aktuellen Format.\n\n${errors.join(
            '\n',
          )}`,
        );
      }
    },
  };
};

export const contractsCsvConfig = (ignoreWarnings?: boolean): CSVConfig => {
  return {
    mutation: createContractsBatched,
    mutationVariableResult: 'contracts',
    mutationVariables: {
      ignoreWarnings,
    },
    validateFn: ({ meta }) => {
      const expectedHeaders: string[] = [
        'VP_NR',
        'VP_TYP',
        'VP_ANREDE_1',
        'VP_NAME_1',
        'VP_DOB_1',
        'VP_COMPANY',
        'VP_COMPANY_CO',
        'VP_EMAIL',
        'VP_TELEFON',
        'VP_MOBIL',
        'VP_WERBUNG',
        'VP_KOMMUNIKATION',
        'VP_STR',
        'VP_HSNR',
        'VP_PLZ',
        'VP_ORT',
        'VP_LK',
        'RE_TYP',
        'RE_ANREDE_1',
        'RE_NAME_1',
        'RE_ANREDE_2',
        'RE_NAME_2',
        'RE_COMPANY',
        'RE_COMPANY_CO',
        'RE_STR',
        'RE_HSNR',
        'RE_PLZ',
        'RE_ORT',
        'RE_LK',
        'LF_TYP',
        'LF_ANREDE_1',
        'LF_NAME_1',
        'LF_COMPANY',
        'LF_COMPANY_CO',
        'LF_STR',
        'LF_HSNR',
        'LF_PLZ',
        'LF_ORT',
        'LF_LK',
        'V_VNR',
        'V_DEBI',
        'V_UNTERSCHRIFT',
        'V_LIEFERBEGINN',
        'V_LIEFERENDE',
        'V_STATUS',
        'V_WORKSPACE',
        'V_TARIF',
        'V_PROFIL',
        'AB_SEPA',
        'AB_KONTOINHABER',
        'AB_IBAN',
        'AB_BIC',
        'AB_REF',
        'AB_UNTERSCHRIFT',
        'AB_ABSCHLAG',
        'AB_BEGINN',
        'AB_AJV',
        'AB_AJV_BEGINN',
        'AB_AJV_ENDE',
        'AB_STICHTAG',
        'AB_TERMIN',
        'AB_TURNUS',
        'Z_NR',
        'Z_MALO',
        'Z_MELO',
      ];

      const errors: string[] = meta.fields.reduce(
        (acc: string[], cur: string, index: number) => {
          if (cur !== expectedHeaders[index]) {
            return [
              ...acc,
              `Erwartet "${expectedHeaders[index]}" in Spalte ${
                index + 1
              }, Datei enthält jedoch "${cur}"`,
            ];
          }
          return acc;
        },
        [],
      );

      if (errors.length) {
        throw Error(
          `Vertragsliste entspricht nicht dem aktuellen Format.\n\n${errors.join(
            '\n',
          )}`,
        );
      }
    },
  };
};

export const tariffsCSVConfig = (): CSVConfig => {
  return {
    mutation: createTariffsBatched,
    mutationVariableResult: 'tariffs',
    keyMap: {
      TARIF_BEZ_EX: 'nameExternal',
      TARIF_BEZ_INT: 'nameInternal',
      GUELTIG_AB: 'validityStartDate',
      GUELTIG_BIS: 'validityEndDate',
      TARIF_ART: 'kind',
      UST: 'tax',
      STROMST: 'powerTax',
      PREISGRNT_BEZUG: 'priceGuaranteeReference',
      PREISGRNT_MNT: 'priceGuarantee',
      MIN_LAUFZEIT_INI: 'minimumDuration',
      MIN_LAUFZEIT_VERL: 'minimumDurationExtension',
      KUENDFRIST_INI: 'noticePeriod',
      KUENDFRIST_INI_BZG: 'noticePeriodReference',
      KUENDFRIST_VERL: 'noticePeriodExtension',
      KUENDFRIST_VERL_BZG: 'noticePeriodExtensionReference',
      PB_NAME: 'priceSheetName',
      PREIS_GUELTIG_AB: 'priceSheetStartDate',
      PREIS_GRUND: 'priceSheetBasicPrice',
      PREIS_ARBEIT: 'priceSheetEnergyPrice',
      PREIS_ARBEIT_LOKAL: 'priceSheetEnergyPriceLocal',
      PREIS_ARBEIT_RESIDUAL: 'priceSheetEnergyPriceResidual',
      PREISGRNT_IGNORE: 'priceSheetIgnorePriceGuarantee',
    },
    validateFn: ({ meta }) => {
      const expectedHeaders: string[] = [
        'NR',
        'TARIF_BEZ_EX',
        'TARIF_BEZ_INT',
        'GUELTIG_AB',
        'GUELTIG_BIS',
        'TARIF_ART',
        'UST',
        'STROMST',
        'PREISGRNT_BEZUG',
        'PREISGRNT_MNT',
        'MIN_LAUFZEIT_INI',
        'KUENDFRIST_INI',
        'KUENDFRIST_INI_BZG',
        'MIN_LAUFZEIT_VERL',
        'KUENDFRIST_VERL',
        'KUENDFRIST_VERL_BZG',
        'PB_NAME',
        'PREIS_GUELTIG_AB',
        'PREIS_GRUND',
        'PREIS_ARBEIT',
        'PREIS_ARBEIT_LOKAL',
        'PREIS_ARBEIT_RESIDUAL',
        'PREISGRNT_IGNORE',
      ];

      const errors: string[] = meta.fields.reduce(
        (acc: string[], cur: string, index: number) => {
          if (cur !== expectedHeaders[index]) {
            return [
              ...acc,
              `Erwartet "${expectedHeaders[index]}" in Spalte ${
                index + 1
              }, Datei enthält jedoch "${cur}"`,
            ];
          }
          return acc;
        },
        [],
      );

      if (errors.length) {
        throw Error(
          `Tarifliste entspricht nicht dem aktuellen Format.\n\n${errors.join(
            '\n',
          )}`,
        );
      }
    },
  };
};

export const metersCSVConfig = (): CSVConfig => {
  return {
    mutation: createMetersBatched,
    mutationVariableResult: 'meters',
    keyMap: {
      GERAET_NR: 'meterNumber',
      GERAET_ZAEHLERPLATZ: 'meterPlace',
      GERAET_ZAEHLERTYP: 'meterType',
      GERAET_WFAKTOR: 'converterFactor',
      MESSART: 'metering',
      PLANT_NAME: 'plantName',
    },
    validateFn: ({ meta }) => {
      const expectedHeaders: string[] = [
        'NR',
        'GERAET_NR',
        'GERAET_ZAEHLERPLATZ',
        'GERAET_ZAEHLERTYP',
        'GERAET_WFAKTOR',
        'MESSART',
        'PLANT_NAME',
      ];

      const errors: string[] = meta.fields.reduce(
        (acc: string[], cur: string, index: number) => {
          if (cur !== expectedHeaders[index]) {
            return [
              ...acc,
              `Erwartet "${expectedHeaders[index]}" in Spalte ${
                index + 1
              }, Datei enthält jedoch "${cur}"`,
            ];
          }
          return acc;
        },
        [],
      );

      if (errors.length) {
        throw Error(
          `Zählerliste entspricht nicht dem aktuellen Format.\n\n${errors.join(
            '\n',
          )}`,
        );
      }
    },
  };
};

export const meterReadingsCSVConfig: CSVConfig = {
  mutation: createMeterReadingsBatched,
  mutationVariableResult: 'meterReadings',
  keyMap: {
    METER_NUMBER: 'meterNumber',
    DATE: 'date',
    METER_READING: 'value',
    REASON: 'reason',
    TYPE: 'hint',
    STATUS: 'valueStatus',
    OBIS: 'obis',
  },
  valueMap: {
    DATE: (dateString: string) =>
      DateTime.fromFormat(dateString, 'dd.MM.yy', {
        locale: 'de',
        zone: 'utc',
      }).toISO(),
    METER_READING: (value: string) => {
      // use csvImport locale here and then revert back to our normal custom locale
      numeral.locale('csvImport');
      const parsedValue = numeral(value).value();
      numeral.locale('custom');
      return parsedValue;
    },
    STATUS: (status: number) => `${status}`,
  },
  validateFn: ({ meta }) => {
    const expectedHeaders: string[] = [
      'METER_NUMBER',
      'MELO',
      'DATE',
      'METER_READING',
      'REASON',
      'TYPE',
      'STATUS',
      'OBIS',
    ];

    const baseError = 'Wechselliste entspricht nicht dem aktuellen Format.';

    const errors: string[] = [];

    meta.fields.forEach((field: string, index: number) => {
      if (field !== expectedHeaders[index]) {
        errors.push(
          `Erwartet: "${expectedHeaders[index]}" in Spalte ${
            index + 1
          }, Datei enthält jedoch "${field}"`,
        );
      }
    });

    if (errors.length) {
      throw Error(`${baseError}\n\n${errors.join('\n')}`);
    }
  },
};

export const bookingsCSVConfig = (): CSVConfig => {
  return {
    mutation: createBookingsBatched,
    mutationVariableResult: 'bookings',
    keyMap: {
      V_VNR: 'contractLabel',
      BUCHUNG_DATUM: 'bookingDate',
      BUCHUNG_WERT: 'bookingValue',
      BUCHUNG_TYP: 'bookingTypeLabel',
    },
    valueMap: {},
    validateFn: ({ meta, ...rest }) => {
      const expectedHeaders: string[] = [
        'V_VNR',
        'BUCHUNG_DATUM',
        'BUCHUNG_WERT',
        'BUCHUNG_TYP',
      ];

      const baseError = 'Wechselliste entspricht nicht dem aktuellen Format.';

      const errors: string[] = [];

      meta.fields.forEach((field: string, index: number) => {
        if (field !== expectedHeaders[index]) {
          errors.push(
            `Erwartet: "${expectedHeaders[index]}" in Spalte ${
              index + 1
            }, Datei enthält jedoch "${field}"`,
          );
        }
      });

      const { data } = rest;
      if (data && data.length > 500) {
        throw Error(
          'Es können maximal 500 Einträge je Upload hochgeladen werden. Bitte passe den Inhalt der Wechselliste entsprechend an.',
        );
      }

      if (errors.length) {
        throw Error(`${baseError}\n\n${errors.join('\n')}`);
      }
    },
  };
};

export default class CSVService {
  keyMap?: KeyMap;

  valueMap?: ValueMap;

  mutation?: DocumentNode;

  mutationVariableResult?: string;

  mutationVariables?: any;

  dynamicTyping?: boolean = false;

  valueDelimiters: { thousands: string; decimal: string } = {
    thousands: ' ',
    decimal: ',',
  };

  validate: (values: ParseResult) => any;

  apolloClient: ApolloClient<any>;

  fileName?: string; // filName used in 'bookings' to archive the imported result file.

  constructor(
    config: CSVConfig,
    apolloClient?: ApolloClient<any>,
    fileName?: string,
  ) {
    this.keyMap = config.keyMap;
    this.valueMap = config.valueMap;
    this.mutation = config.mutation;
    this.mutationVariables = config.mutationVariables;
    this.mutationVariableResult = config.mutationVariableResult;
    this.dynamicTyping = config.dynamicTyping;
    this.validate = config.validateFn;
    this.fileName = fileName;

    if (config.valueDelimiters) this.valueDelimiters = config.valueDelimiters;

    this.apolloClient = apolloClient ?? client;

    if (!numeral.locales.csvimport) {
      numeral.register('locale', 'csvImport', {
        delimiters: this.valueDelimiters,
        abbreviations: {
          thousand: 'k',
          million: 'm',
          billion: 'b',
          trillion: 't',
        },
        ordinal() {
          return '.';
        },
        currency: {
          symbol: '€',
        },
      });
    } else {
      numeral.locales.csvimport.delimiters = this.valueDelimiters;
    }
  }

  /**
   * Updates the service config
   * @param config
   */
  setConfig(
    config: CSVConfig,
    apolloClient?: ApolloClient<any>,
    fileName?: string,
  ) {
    this.keyMap = config.keyMap;
    this.valueMap = config.valueMap;
    this.mutation = config.mutation;
    this.mutationVariables = config.mutationVariables;
    this.mutationVariableResult = config.mutationVariableResult;
    this.dynamicTyping = config.dynamicTyping;
    this.validate = config.validateFn;
    this.fileName = fileName;

    if (config.valueDelimiters) {
      this.valueDelimiters = config.valueDelimiters;
      numeral.locales.csvImport.delimiters = config.valueDelimiters;
    }

    this.apolloClient = apolloClient ?? client;
  }

  /**
   * converts an array of objects into a CSV string format
   * @param data
   * @param config
   */
  static toCSV = (
    data: any[],
    { headers, fields }: CSVConvertConfig,
    config?: UnparseConfig,
  ): string => {
    return Papa.unparse(
      {
        fields: headers,
        data: data.map((values: { [key: string]: any }) =>
          fields.map((field: string) => values[field]),
        ),
      },
      config,
    );
  };

  /**
   * converts an array of objects into a CSV string format and downloads it
   * @param data
   * @param config
   */
  static downloadCSV = (
    data: any[],
    config: CSVConvertConfig,
    unparsingConfig?: UnparseConfig,
  ) => {
    const parsedCsv = CSVService.toCSV(data, config, unparsingConfig);

    saveAs(
      new Blob([parsedCsv], { type: 'text/csv;charset=utf-8' }),
      config.fileName,
    );
  };

  static formatNumber = (number: number) => numeral(number).format('0.00');

  static formatDate = (d: string) => DateTime.fromISO(d).toFormat('dd.MM.yyyy');

  /**
   * push the parsed results to the server through mutation
   * @param results
   */
  push = async (results: StringObject[]) => {
    if (!this.mutation) {
      throw Error('CSV_PUSH_FAILED: Mutation not defined');
    } else if (!this.mutationVariableResult) {
      throw Error('CSV_PUSH_FAILED: mutationVariableResult not defined');
    } else {
      return this.apolloClient
        .mutate({
          mutation: this.mutation,
          variables: {
            [this.mutationVariableResult]: results,
            ...(this.mutationVariables || {}),
            ...(this.fileName ? { fileName: this.fileName } : null),
          },
          errorPolicy: 'all',
        })
        .then(({ data, errors }) => {
          if (data)
            return data[(this.mutation?.definitions[0] as any).name.value];
          if (Array.isArray(errors)) {
            const messages = errors
              .map((error) => {
                return error?.extensions?.error?.title ?? error?.message;
              })
              .join(' ');
            throw new Error(messages);
          }
          throw new Error('Unbekannter Fehler');
        });
    }
  };

  /**
   * transform the parsed csv file
   * @param data
   */
  transform = (data: StringObject[]) => {
    return this.keyMap
      ? data.map((obj) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          transformObject(obj, this.keyMap!, this.valueMap, true),
        )
      : data;
  };

  /**
   * parse a single CSV File into a JSON format according to a keyMap and a valueMap
   * @param file
   */
  parse = (file: File | string): Promise<ParseResult> => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        dynamicTyping: this.dynamicTyping,
        skipEmptyLines: true,
        complete: ({ data, errors, meta }) => {
          if (errors.length) {
            return reject(errors);
          }
          resolve({ data: data as StringObject[], meta });
        },
      });
    });
  };

  /**
   * iterate over an array of files, parse them and push the values to server
   * @param files
   */
  uploadFiles = async (files: (File | string)[]) => {
    return Promise.all(files.map((file) => this.parse(file)))
      .then((parsedFiles) => {
        parsedFiles.forEach((parsedFile) => this.validate(parsedFile));
        return parsedFiles;
      })
      .then((parsedFiles) =>
        parsedFiles.map(({ data }) => this.transform(data)),
      )
      .then((mappedData) =>
        mappedData.reduce(
          (merged: StringObject[], data: StringObject[]) => merged.concat(data),
          [] as StringObject[],
        ),
      )
      .then((results) => this.push(results));
  };
}
