import * as React from 'react';
import { FormikContext, useFormik } from 'formik';
import * as yup from 'yup';
import styled, { useTheme } from 'styled-components/macro';
import {
  AlertRetryable,
  Expandable,
  Expandables,
  Icons,
  SubTitle,
} from '@ampeersenergy/ampeers-ui-components';

import {
  AccountingRunAutarkySuggestion,
  ActionOption,
  AutarkyStepResult,
  SetAccountingRunPlantAutarkyDocument,
  StepStatus,
  WorkflowType,
  useReadPlantsAutarkiesQuery,
} from '../../../../graphql-types';
import { AccountingRunStepRef } from '../types';
import { SetPlantAutarky } from '../../oldWorkflow/types';
import { TooltipInfo } from '../../../../components/TooltipInfo';

import { PlantAutarkyFormValues, PlantList } from './PlantList';
import type { PlantSuggestionValues } from './PlantList';

const SubtitleDescription = styled.div`
  color: ${({ theme }) => theme.palette.textMuted};
  margin: 15px 0;
  font-size: 14px;
  font-weight: initial;
`;

const ErrorDisplay = styled.p`
  color: ${({ theme }) => theme.palette.error.color};
  margin-bottom: 0;
`;

function suggestionHasError(
  s: AccountingRunAutarkySuggestion,
): s is PlantSuggestionValues & { errorReason: string } {
  return !!s.errorReason;
}

function transformSucceededStepSuggestions(
  result: AutarkyStepResult,
): PlantSuggestionValues[] {
  const { plants, userInput } = result;
  const userInputSuggestions = userInput?.reduce((values, input) => {
    const plantInfo = plants?.find((plant) => plant?.plantId === input.plantId);
    if (!input || !plantInfo) {
      return values;
    }
    return [
      ...values,
      {
        ...plantInfo,
        ...input,
        value: input.autarky,
        isSelected: true,
        __typename: 'AccountingRunAutarkySuggestion' as const,
      },
    ];
  }, [] as PlantSuggestionValues[]);

  const userInputSuggestionsIds = userInputSuggestions?.map((suggestion) =>
    suggestion.plantId.toString(),
  );

  const excludedPlants = plants
    ?.filter(
      (suggestion) =>
        (suggestion?.autarky === null || suggestion?.autarky === undefined) &&
        !userInputSuggestionsIds?.includes(
          suggestion?.plantId.toString() || '',
        ),
    )
    .map(
      (suggestion) =>
        ({ ...suggestion, isSelected: false }) as PlantSuggestionValues,
    );
  return [...(userInputSuggestions ?? []), ...(excludedPlants ?? [])];
}

export const exportedForTesting = {
  transformSucceededStepSuggestions,
};

const validationSchema = yup.object().shape({
  suggestions: yup.array().of(
    yup.object().shape({
      plantId: yup.number().required(),
      value: yup
        .number()
        .when('isSelected', {
          is: true,
          then: yup.number().required(),
        })
        .min(0)
        .max(100),
      isSelected: yup.boolean(),
    }),
  ),
});

function parseValues(values: PlantAutarkyFormValues) {
  return values.suggestions.reduce(
    (payload, { value, plantId, isSelected }) => {
      if (!isSelected) {
        return payload;
      }
      return [
        ...payload,
        {
          plantId,
          autarky: value ? parseFloat(`${value}`) : 0,
        },
      ];
    },
    [] as SetPlantAutarky[],
  );
}

interface AutarkyStepProps {
  result: AutarkyStepResult;
  status?: StepStatus;
  isEditable?: boolean;
  stepAction?: ActionOption;
  paymentPeriodStartAt?: string;
  paymentPeriodEndAt?: string;
}

export const AutarkyStep = React.forwardRef<
  AccountingRunStepRef,
  AutarkyStepProps
>(
  (
    {
      result,
      status,
      isEditable,
      stepAction,
      paymentPeriodStartAt,
      paymentPeriodEndAt,
    },
    ref,
  ) => {
    const {
      data: plantsData,
      loading: loadingPlants,
      error: plantsError,
    } = useReadPlantsAutarkiesQuery({
      variables: {
        ids: result.plants
          ?.map((plant) => (plant ? `${plant.plantId}` : undefined))
          .filter((id): id is string => !!id),
      },
    });
    const theme = useTheme();
    const [errorMessage, setErrorMessage] = React.useState('');
    const { workflowType } = result;
    const initialValues = React.useMemo(() => {
      const { plants, userInput } = result;
      if (
        status === StepStatus.Succeeded &&
        userInput?.length &&
        userInput?.length > 0
      ) {
        return { suggestions: transformSucceededStepSuggestions(result) };
      }

      return {
        suggestions:
          plants?.reduce((values, suggestion) => {
            if (!suggestion) {
              return values;
            }

            return [
              ...values,
              {
                ...suggestion,
                value: suggestionHasError(suggestion)
                  ? ''
                  : (suggestion.autarky ?? ''),
                isSelected: isEditable || !suggestionHasError(suggestion),
              },
            ];
          }, [] as PlantSuggestionValues[]) ?? [],
      };
    }, [isEditable, result, status]);

    const formikContext = useFormik({
      initialValues,
      validationSchema,
      validateOnMount: true,
      validateOnBlur: true,
      onSubmit: () => {},
    });

    React.useImperativeHandle(ref, () =>
      isEditable
        ? {
            getTransitionConfig() {
              return {
                mutation: SetAccountingRunPlantAutarkyDocument,
                variables: {
                  payload: {
                    autarkies: parseValues(formikContext.values),
                  },
                },
              };
            },
            async isValid() {
              await formikContext.validateForm();
              return formikContext.isValid;
            },
          }
        : undefined,
    );

    const plantsWithoutErrors = initialValues.suggestions.filter(
      (suggestion) =>
        suggestion.autarky !== null && suggestion.autarky !== undefined,
    );
    const plantsWithErrors = initialValues.suggestions.filter(
      (suggestion) =>
        suggestion.autarky === null || suggestion.autarky === undefined,
    );
    return (
      <FormikContext.Provider value={formikContext}>
        {plantsError && <AlertRetryable error={plantsError} />}
        {plantsWithoutErrors.length > 0 && (
          <SubTitle>
            Erfolgreiche{' '}
            {workflowType === WorkflowType.Single
              ? 'Autarkiebestimmung'
              : 'Autarkieberechnung'}
            <SubtitleDescription>
              {workflowType === WorkflowType.Single
                ? `Für die folgende Kundenanlage in diesen Abrechnungszeitraum ist der Autarkiewert aus 
              der Historie bekannt. Dieser wird automatisch für diesen Rechnungslauf genutzt.`
                : `Folgende Autarkien können entweder durch eingetragene Zählerstände
            berechnet werden oder sind aufgrund von bereits früher
            durchgeführten Berechnungen bekannt.`}
            </SubtitleDescription>
            {/* @ts-expect-error old Expandables */}
            <Expandables expandedIndex={0} showChevron>
              {/* @ts-expect-error old Expandable */}
              <Expandable
                title={
                  <div css="display: flex; align-items: center;">
                    <div>
                      <Icons.Checkmark
                        size={30}
                        color={theme.palette.success.color}
                      />
                    </div>
                    <span>
                      {workflowType === WorkflowType.Single
                        ? `Erfolgreiche Autarkiebestimmung`
                        : `${plantsWithoutErrors.length} Erfolgreiche Autarkieberechnung`}
                    </span>
                  </div>
                }
              >
                <PlantList
                  isEditable={isEditable}
                  isSuccess
                  setErrorMessage={setErrorMessage}
                  plantAutarkies={plantsData?.readPlantsAutarkies ?? []}
                  paymentPeriodStartAt={paymentPeriodStartAt}
                  paymentPeriodEndAt={paymentPeriodEndAt}
                  loadingPlants={loadingPlants}
                />
              </Expandable>
            </Expandables>
          </SubTitle>
        )}
        {plantsWithErrors.length > 0 && (
          <>
            <SubTitle>Problembehebung durch Dich</SubTitle>
            {/* @ts-expect-error old Expandables */}
            <Expandables showChevron expandedIndex={0}>
              {/* @ts-expect-error old Expandable */}
              <Expandable
                title={
                  <div css="display: flex; align-items: center;">
                    <div>
                      <Icons.Warning
                        size={30}
                        color={theme.palette.warning.color}
                      />
                    </div>
                    {workflowType === WorkflowType.Single ? (
                      <span>Keine Autarkie gefunden</span>
                    ) : (
                      <span>
                        {plantsWithErrors.length} fehlerhafte
                        {plantsWithErrors.length === 1
                          ? ' Autarkie '
                          : ' Autarkien '}
                        gefunden
                      </span>
                    )}
                  </div>
                }
              >
                {workflowType === WorkflowType.Single ? (
                  <SubtitleDescription>
                    Für die folgende Kundenanlage haben wir in diesem
                    Abrechnungszeitraum keinen Autarkiewert aus der Historie
                    gespeichert. Bitte gib einen Ersatzwert ein.
                  </SubtitleDescription>
                ) : (
                  <>
                    {plantsWithErrors.length === 1 ? (
                      <SubtitleDescription>
                        Für die folgende Kundenanlage konnte keine Autarkie
                        berechnet werden. Bitte gib einen Ersatzwert ein.
                      </SubtitleDescription>
                    ) : (
                      <SubtitleDescription>
                        Für die folgenden Kundenanlagen konnte keine Autarkie
                        berechnet werden. Bitte überprüfe die Zählerstände für
                        alle aktiven Verbrauchsstellen im Abrechnungszeitraum.
                        Optional kannst Du einen Ersatzwert
                        <TooltipInfo
                          id="addressTooltip"
                          dark
                          maxWidth="400px"
                          text="Ein Ersatzwert ist ein abrechnungsrelevanter Messwert, der unter Verwendung aller verfügbaren Informationen anstelle eines fehlenden wahren Messwertes, eines vorläufigen Messwertes oder eines unplausiblen wahren Messwerts gebildet wird."
                        />
                        eingeben oder die Kundenanlage vom Abrechnungslauf
                        ausschließen
                      </SubtitleDescription>
                    )}
                  </>
                )}
                <PlantList
                  isEditable={isEditable}
                  setErrorMessage={setErrorMessage}
                  stepAction={stepAction}
                  plantAutarkies={plantsData?.readPlantsAutarkies ?? []}
                  paymentPeriodStartAt={paymentPeriodStartAt}
                  paymentPeriodEndAt={paymentPeriodEndAt}
                  loadingPlants={loadingPlants}
                />
              </Expandable>
            </Expandables>
          </>
        )}
        {errorMessage && <ErrorDisplay>{errorMessage}</ErrorDisplay>}
      </FormikContext.Provider>
    );
  },
);
