import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Route, Routes, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import {
  WorkflowStep,
  ButtonGroup,
  Button,
  PaddedShadowBox,
  FlexRow,
  DialogProvider,
} from '@ampeersenergy/ampeers-ui-components';

import { ErrorMsg, PageTitle } from '../../../components';
import ContractIndexPage from '../../contract/pages/index';
import { useReadWorkflowQuery } from '../../../graphql-types';
import transitionWorkflowMutation from '../../../queries/transitionWorkflowMutation';
import ErrorBoundary from '../../../components/errorBoundary';
import { DocTitle } from '../../../components/docTitle';
import { accountingTitle } from '../accounting.page';
import { PageTitleLayout } from '../../../components/new/PageTitleLayout';

import { MixedResult } from './mixedResult';
import WorkflowSummary from './workflowSummary';
import SuccessResult from './successResult';
import { ValidateReadingsResult } from './validate/result';
import { PlantAutarky } from './autarky/plantAutarky';
import { ImportDebitPositionsOverview } from './importDebitPositionsOverview';
import { StepsSidebar } from './stepsSidebar';
import { StepActive } from './stepActive';
import { OPCResult } from './opcResult';
import { NavigationActions } from './navigationActions';
import { DownpaymentForm } from './DownpaymentForm';

const Wrapper = styled(FlexRow)``;
const Steps = styled(PaddedShadowBox)`
  padding: 10px;
  margin-right: 5px;
  width: 300px;
`;

const StepDetails = styled(PaddedShadowBox)`
  flex: 1;
`;

const StepTitle = styled(PageTitle)`
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
`;

const StyledButtons = styled(ButtonGroup)`
  margin-top: 15px;
`;

const renderTransitionLabel = (meta: any, fallback: string) => {
  if (meta) {
    if (meta.name) {
      return meta.name;
    }

    if (meta.type) {
      switch (meta.type) {
        case 'retry':
          return 'Schritt wiederholen';
        case 'next':
          return 'Nächster Schritt';
        default:
          return '';
      }
    }
  }

  return fallback;
};

function AccountingDetailPage() {
  const client = useApolloClient();
  const { id, name } = useParams<{
    id: string;
    name: string;
  }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const userSelected = searchParams.get('step');
  const [isLoading, setIsLoading] = useState(false);
  const [updateStep, setUpdateStep] = useState(false);
  const { data, loading, error, refetch } = useReadWorkflowQuery({
    variables: {
      workflowId: id!,
    },
  });
  let context: any;
  const AccountingDocTitle = React.useCallback(
    () => <DocTitle titleParts={[name!, accountingTitle]} />,
    [name],
  );

  const [mutationError, setMutationError] = useState<any>(null);
  // const {
  //   data: subscriptionData,
  //   error: subscriptionError,
  // } = useOnWorkflowChangeSubscription();

  const workflow = data?.readWorkflow;

  // useEffect(() => {
  //   if (subscriptionData?.workflowChange?.workflowId === id) {
  //     refetch();
  //   }
  // }, [id, refetch, subscriptionData]);

  /**
   * refetch the workflow when the current step is
   * active. obsolete when we active
   * websockets again.
   */
  useEffect(() => {
    if (!workflow) return;
    if (!userSelected) return;

    const curStep = workflow.currentState.context[userSelected];

    if (curStep?.uiState !== 'active') {
      return;
    }
    const timer = setInterval(() => {
      refetch();
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [refetch, userSelected, workflow]);

  /**
   * show next step when a update arrives
   */
  useEffect(() => {
    if (!workflow) {
      return;
    }

    const nextStep =
      workflow.currentState.parentName ?? workflow.currentState.name;

    if (!nextStep) {
      return;
    }

    /**
     * we have a update of the step scheduled (updateStep=true)
     * and the nextStep is different than the userSelected
     * or the userSelected nothing
     */
    if (!userSelected) {
      setSearchParams({ step: nextStep });
      return;
    }

    if (updateStep && nextStep !== userSelected) {
      setUpdateStep(false);
      setSearchParams({ step: nextStep });
    }
  }, [data, setSearchParams, updateStep, userSelected, workflow]);

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const childRef = useRef<any>();

  const doTransitionTo = useCallback(
    async ({ transitionName }: { transitionName?: string }) => {
      const allowed =
        childRef?.current?.allowTransition?.(transitionName) ?? true;

      if (!allowed) {
        return;
      }

      const stepTransitionConfig =
        childRef?.current?.getTransitionConfig?.(transitionName) ?? {};

      setUpdateStep(true);
      setIsLoading(true);

      try {
        await client.mutate({
          mutation: stepTransitionConfig.mutation ?? transitionWorkflowMutation,
          variables: {
            workflowId: id,
            transitionName,
            ...(stepTransitionConfig && stepTransitionConfig.variables),
          },
        });
        setMutationError(null);
      } catch (err) {
        setMutationError(err);
      }

      setIsLoading(false);
    },
    [client, id],
  );

  let detailView;

  if (workflow && userSelected) {
    const { currentState, meta } = workflow;
    ({ context } = currentState);
    let result;
    const stepContext = context[userSelected];
    if (stepContext?.uiState === 'active') {
      result = <StepActive />;
    } else {
      switch (stepContext?.component) {
        case 'MixedResult':
          result = <MixedResult contracts={stepContext.contracts} />;
          break;
        case 'ValidateReadingsResult':
          result = (
            <ValidateReadingsResult
              contracts={stepContext.contracts}
              additionalErrors={stepContext.additionalErrors}
            />
          );
          break;
        case 'ImportDebitPositionsOverview': {
          const step = currentState.name.split('.')[1];
          const hint =
            step === 'success'
              ? 'overview'
              : step === 'summary'
                ? 'summary'
                : undefined;
          result = (
            <ImportDebitPositionsOverview
              invoices={stepContext.invoices}
              hint={hint}
              contractId={
                context.validateContractMeters?.contracts?.find(
                  ({ id: contractId }: { id: string }) =>
                    contractId === context.payload.contractsToAccount,
                )?.contractLabel
              }
              paymentPeriodStartAt={context.payload.paymentPeriodStartAt}
              paymentPeriodEndAt={context.payload.paymentPeriodEndAt}
            />
          );
          break;
        }
        case 'PlantAutarky':
          result = (
            <PlantAutarky
              suggestions={
                stepContext?.uiState === 'success'
                  ? stepContext.userInput
                  : stepContext?.plants
              }
              isEditable={
                currentState.name === 'calculateAutarky.success' ||
                currentState.name === 'calculateAutarky.overwriteAutarky' ||
                currentState.name === 'calculateAutarky.successWithoutRetry'
              }
              ref={childRef}
            />
          );
          break;
        case 'DownpaymentForm':
          result = (
            <DownpaymentForm
              isEditable={currentState.name === 'downpaymentStart.run'}
              startDate={stepContext.startDate}
              ref={childRef}
            />
          );
          break;
        case 'Error':
          result = (
            <ErrorMsg
              title={stepContext.error?.title}
              message={stepContext.error?.message}
              retryable={false}
            />
          );
          break;
        case 'Summary':
          result = (
            <WorkflowSummary
              steps={workflow.stateNames}
              context={context}
              meta={meta}
              createdAt={workflow.createdAt}
              doneAt={workflow.doneAt}
            />
          );
          break;
        case 'Success':
          result = <SuccessResult />;
          break;
        case 'OPCResult':
          result = (
            <OPCResult
              succeeded={stepContext.opcPayload.passed}
              failed={stepContext.opcPayload.errored}
            />
          );
          break;

        default:
          result = '';
          break;
      }
    }

    detailView = (
      <>
        <StepTitle>
          {workflow.meta[userSelected]?.name ?? userSelected}
        </StepTitle>
        {mutationError && <ErrorMsg error={mutationError} />}
        {/* {subscriptionError && <ErrorMsg error={subscriptionError} />} */}
        {result}
      </>
    );
  }

  if (error) {
    return (
      <>
        <AccountingDocTitle />
        <PageTitleLayout
          levelsUpToParent={1}
          title={name!}
          actions={<NavigationActions currentWorkflowId={id!} />}
        >
          <ErrorMsg error={error} />
        </PageTitleLayout>
      </>
    );
  }

  if (loading) {
    return (
      <>
        <AccountingDocTitle />
        <PageTitleLayout
          levelsUpToParent={1}
          title={name!}
          actions={<NavigationActions currentWorkflowId={id!} />}
        >
          <Wrapper>
            <Steps>
              {new Array(5).fill(null).map((_, index: number) => (
                <WorkflowStep
                  label=""
                  state="loading"
                  onClick={() => null}
                  isSelected={false}
                  key={index}
                />
              ))}
            </Steps>
            <StepDetails />
          </Wrapper>
        </PageTitleLayout>
      </>
    );
  }

  const isOpcCreated = !!context?.createAccounting;
  return (
    <DialogProvider>
      <AccountingDocTitle />
      <PageTitleLayout
        levelsUpToParent={1}
        title={name!}
        actions={
          <>
            <NavigationActions
              currentWorkflowId={id!}
              uuid={context?.executionContext?.session}
              name={name}
              isOpcCreated={isOpcCreated}
            />
          </>
        }
      >
        <Wrapper>
          <Steps>
            <StepsSidebar
              workflow={workflow}
              userSelected={userSelected}
              setUserSelected={(step) => setSearchParams({ step })}
            />
          </Steps>
          <StepDetails>
            {detailView && <ErrorBoundary>{detailView}</ErrorBoundary>}
            <StyledButtons>
              {userSelected &&
                (workflow?.currentState.name === userSelected ||
                  workflow?.currentState.parentName === userSelected) &&
                workflow?.currentState.context[userSelected]?.uiState !==
                  'active' &&
                workflow?.currentState.transitions
                  .filter(() => {
                    // if (
                    //   /**
                    //    * when the step has meta {retryable} and it not done
                    //    */
                    //   !(
                    //     workflow.meta[userSelected].retryable &&
                    //     workflow?.currentState.context[userSelected]?.uiState !==
                    //       "done"
                    //   ) &&
                    //   !(
                    //     /**
                    //      * when the step is a retry step and the step failed
                    //      */
                    //     (
                    //       workflow.meta[t]?.type === "retry" &&
                    //       workflow?.currentState.context[userSelected]
                    //         ?.uiState === "failed"
                    //     )
                    //   )
                    // ) {
                    //   return false;
                    // }

                    return true;
                  })
                  .map((transitionName: string) => (
                    <Button
                      key={transitionName}
                      onClick={() => doTransitionTo({ transitionName })}
                      isLoading={isLoading}
                      disabled={isLoading}
                    >
                      {renderTransitionLabel(
                        workflow.meta[transitionName],
                        transitionName,
                      )}
                    </Button>
                  ))}
            </StyledButtons>
          </StepDetails>
        </Wrapper>
      </PageTitleLayout>
      <Routes>
        <Route path="contract/:contractId" element={<ContractIndexPage />} />
      </Routes>
    </DialogProvider>
  );
}

export default AccountingDetailPage;
