import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { AlertRetryable, Button } from '@ampeersenergy/ampeers-ui-components';

import { ButtonWrap, Iframe } from '../style';

function isPercentage(value: string | number) {
  return value.toString().includes('%');
}

export type PreviewDimensions = {
  width?: number | string;
  height?: number | string;
};

type PdfPreviewProps = {
  isLoading?: boolean;
  fileSrc?: string;
  error?: string;
  dimensions?: PreviewDimensions;
  onNext: () => void;
};

export function PdfPreview({
  onNext,
  isLoading,
  fileSrc,
  error,
  dimensions = {
    width: 600,
    height: 900,
  },
}: PdfPreviewProps) {
  const width = !isPercentage(dimensions.width ?? 900)
    ? `${dimensions.width}px`
    : (dimensions.width ?? 900);
  const height = !isPercentage(dimensions.height ?? 900)
    ? `${dimensions.height}px`
    : (dimensions.height ?? 900);

  return (
    <div>
      {isLoading ? (
        <ContentLoader height={height} width={width} speed={2}>
          <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />
        </ContentLoader>
      ) : (
        <>
          {error && (
            <AlertRetryable
              title="Fehler beim Erstellen der Vorschau"
              message={error}
              retryable={false}
            />
          )}
          {fileSrc && (
            <Iframe
              src={`${fileSrc}&delivery=inline`}
              scrolling="auto"
              height={(dimensions.height ?? 900).toString()}
              width={(dimensions.width ?? 600).toString()}
              data-testid="preview-iframe"
            />
          )}
        </>
      )}
      <ButtonWrap>
        <Button disabled={isLoading || !!error} onClick={onNext}>
          Weiter
        </Button>
      </ButtonWrap>
    </div>
  );
}
