import { ApolloClient, ApolloError, ServerError } from '@apollo/client';
import { diff, applyChange } from 'deep-diff';
import gql from 'graphql-tag';
import { cloneDeep, merge } from 'lodash';
import { GraphQLError } from 'graphql';

import { ValidationErrors } from '../../graphql-types';

import { __DEV__ } from './isDev';
import { buildMutationDocument } from './schema';
import { castValuesRecursive } from './mapper';
import { IbanExistsErrorClass } from './types';

export function cleanValues(values: any) {
  const { ...rest } = values;
  return rest;
}

const filterEmpty = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(filterEmpty);
  }
  if (obj && typeof obj === 'object') {
    const r = Object.keys(obj).reduce((acc, _) => {
      const f = filterEmpty(obj[_]);
      return {
        ...acc,
        ...(f !== undefined && {
          [_]: f,
        }),
      };
    }, {});

    if (Object.keys(r).length !== 0) {
      return r;
    }
    return undefined;
  }
  if (obj !== undefined && obj !== '') {
    return obj;
  }
  if (obj === '') {
    return null;
  }

  return undefined;
};

const customDiff = (objA: any, objB: any): { [key: string]: any } | null => {
  const objAClone = cloneDeep(objA);
  const objBClone = cloneDeep(objB);
  const diffs = diff(objAClone, objBClone);

  if (!diffs) {
    return null;
  }

  const target = {};
  diffs.forEach((_) => applyChange(target, true, _));
  return filterEmpty(target);
};

interface SubmitArgs {
  newValues: any;
  values?: any;
  initialValues: { [key: string]: any };
  fieldProps: Map<string, any>;
  refetchQueries?: any[];
  mutation: string;
  schema: any;
  readDocument?: any;
  readDocumentFields?: string[];
  client: ApolloClient<any>;
  variables?: any;
}

export const submit = async (
  {
    newValues,
    values,
    initialValues,
    fieldProps,
    refetchQueries,
    mutation,
    schema,
    readDocument,
    readDocumentFields,
    client,
    variables,
  }: SubmitArgs,
  submitAll = false,
) => {
  let diffedValues: { [key: string]: any } | null = null;
  if (submitAll) {
    diffedValues = newValues;
  } else {
    diffedValues = values
      ? customDiff(cleanValues(values), newValues)
      : customDiff(initialValues, newValues);
  }

  if (diffedValues === null) {
    return;
  }

  // if (__DEV__) {
  //   console.log("GraphQLForm: casting", diffedValues);
  // }

  const castedDiff = castValuesRecursive(fieldProps, diffedValues);

  if (!castedDiff) {
    return;
  }

  if (__DEV__) {
    // console.log("GraphQLForm: Saving", castedDiff);
    // console.log("GraphQLForm: Refetch Queries", refetchQueries);
  }

  /**
   * build the mutation
   */
  const { mutationSourceString, inputObjectName } = buildMutationDocument(
    mutation,
    schema,
    readDocument,
    readDocumentFields,
  );

  if (!inputObjectName) {
    throw new Error('No InputObject');
  }

  const gqlMutation = gql(mutationSourceString);
  const payload = merge(variables, {
    [inputObjectName]: castedDiff,
  });

  try {
    const result: any = await client.mutate({
      mutation: gqlMutation,
      variables: payload,
      refetchQueries,
      errorPolicy: 'all',
    });

    if (result.errors) {
      throw result.errors;
    }

    if (result.data[mutation].__typename === 'ValidationErrors') {
      throw result.data[mutation];
    }

    if (result.data[mutation].__typename === 'IbanExistsError') {
      const {
        message,
        accountHolderId,
        accountNumber,
        accountHolder,
        payers,
        hasNewMeterBeenAdded,
      } = result.data[mutation];

      throw new IbanExistsErrorClass(
        message,
        accountHolderId,
        accountNumber,
        accountHolder,
        payers,
        hasNewMeterBeenAdded,
      );
    }

    return result;
  } catch (error) {
    const errors = [];

    // TODO handle graphql errors
    if ((error as ValidationErrors).__typename === 'ValidationErrors') {
      errors.push(...(error as ValidationErrors).errors);
    } else if (error instanceof IbanExistsErrorClass) {
      throw error;
    } else if (Array.isArray(error)) {
      error.forEach((e: GraphQLError) => {
        const exception = e?.extensions?.exception as any;
        if (exception?.traceId) {
          errors.push({
            message: `${
              exception.stacktrace[0]?.includes('Odoo')
                ? 'Odoo'
                : exception.stacktrace[0]?.includes('PHT')
                  ? 'PHT'
                  : ''
            }: ${e.message}`,
            traceId: exception.traceId,
            context: e.path ? e.path[0] : '',
            payload,
          });
        } else {
          errors.push(e.message);
        }
      });
    } else if (
      error instanceof ApolloError &&
      ((error.networkError as ServerError)?.result as any)?.errors
    ) {
      ((error.networkError as ServerError)?.result as any)?.errors.forEach(
        (e: any) => {
          errors.push(e.message);
        },
      );
    } else {
      errors.push((error as any).toString());
    }

    throw errors;
  }
};
