import { DateTime } from 'luxon';
import { err, ok, Result } from 'neverthrow';
import React, { useCallback } from 'react';

import {
  CreateBookingsBatchedValidatonError,
  CreateBookingsBatchedSuccess,
  InfoContractVoucherBatched,
} from '../../../graphql-types';
import {
  ImportFlow,
  ImportError,
  ImportHandler,
  ImportResult,
  ImportType,
} from '../../../components/importer/import-flow';
import { DocTitle } from '../../../components/docTitle';
import CSVService, { bookingsCSVConfig } from '../../../services/csv';
import { formatDate } from '../../../helpers/formatStrings';

const csvService: CSVService = new CSVService(bookingsCSVConfig());

function createErrorMessage(e: unknown) {
  let message: string;
  if (Array.isArray(e)) {
    message = e.map((_error) => _error.message).join(',');
  } else if (e instanceof Error) {
    message = e.message.toString();
  } else {
    message = String(e);
  }
  return message;
}

export function UploadBookingModal({
  setIsOpen,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const bookingImportModalType: ImportType = {
    label: 'Buchungsstapel (AE-Wechselliste)',
    id: 'bookings',
    templateURL: '/bookings_upload_template.csv',
    mimeTypes: ['text/csv', 'application/vnd.ms-excel'],
    acceptedExtensions: ['csv'],
    indeterminateProgress: true,
    importTypeInstructions: 'csv',
  };

  const onImport: ImportHandler = async ({ file, error, fileName }) => {
    if (!file || error) {
      const errorMsg: string = error instanceof Error ? error.message : error!;
      return err({
        title: importErrorTitle,
        message: errorMsg.replace(
          'File type must be',
          'Datei muss folgendes Format haben:',
        ),
      });
    }

    try {
      csvService.setConfig(bookingsCSVConfig(), undefined, fileName);
      const uploadResult = await csvService.uploadFiles([file]);

      if (uploadResult.__typename === 'CreateBookingsBatchedError') {
        const failed = uploadResult.failed.map(
          (failedResult: CreateBookingsBatchedValidatonError) => {
            return {
              error: failedResult.message,
              values: [failedResult.contractLabel],
            };
          },
        );
        const warningMessage = (
          <div>
            <WarningMessages
              message="Die unten stehenden Fehler verhindern den Import der Buchungen. Bitte
          korrigiere die Einträge, um mit dem Import fortzufahren."
            />
            {uploadResult.info.length > 0 ? (
              <WarningMessages info={uploadResult.info} />
            ) : undefined}
          </div>
        );

        return ok({
          failed,
          backgroundTask: false,
          headers: ['Vertrags-Nr.'],
          warningMessage,
        });
      }
      if (uploadResult.__typename === 'CreateBookingsBatchedSuccess') {
        const succeeded = uploadResult.succeeded.map(
          (succeededResult: CreateBookingsBatchedSuccess['succeeded'][0]) => {
            const {
              contractId,
              date,
              value,
              type: voucherType,
            } = succeededResult || {};
            const parsedDate = DateTime.fromISO(date);
            return {
              values: [
                contractId,
                parsedDate.toFormat('dd.MM.yyyy'),
                value.toLocaleString(),
                voucherType,
              ],
            };
          },
        );

        const warningMessage =
          uploadResult.info.length > 0 ? (
            <WarningMessages info={uploadResult.info} />
          ) : undefined;

        return ok({
          succeeded,
          warningMessage,
          headers: ['Vertragsnummer', 'Datum', 'Wert', 'Typ'],
        });
      }
    } catch (e) {
      return err({
        message: createErrorMessage(e),
        title: importErrorTitle,
      });
    }
    return err({
      title: importErrorTitle,
      message: 'Trying to import unsupported type',
    });
  };

  const onImportOverviewDownload = useCallback(
    (result: Result<ImportResult, ImportError>) => {
      if (result.isOk()) {
        const fields = ['contractId', 'date', 'value', 'type'];
        const headers = [
          'V_VNR',
          'BUCHUNG_DATUM',
          'BUCHUNG_WERT',
          'BUCHUNG_TYP',
        ];
        const date = DateTime.utc().setLocale('de').toLocal();
        const fileNameDate = date.toFormat('ddMMyyyyHHmm');
        const fileName = `${fileNameDate}_Importlog_Buchung.csv`;

        const config = { fields, headers, fileName };

        const succeeded =
          result.value.succeeded?.map((row) => {
            return {
              contractId: row.values[0],
              date: row.values[1],
              value: row.values[2],
              type: row.values[3],
            };
          }) ?? [];

        const failed =
          result.value.failed?.map((row) => {
            return {
              contractId: row.values[0],
              date: row.values[1],
              value: row.values[2],
              type: row.values[3],
            };
          }) ?? [];

        return CSVService.downloadCSV([...succeeded, ...failed], config);
      }
      console.error(
        'Something went wrong while trying to generate import overview',
        { error: result.error },
      );
    },
    [],
  );

  return (
    <>
      <DocTitle titleParts={['Import']} />
      <ImportFlow
        types={[bookingImportModalType]}
        onImport={onImport}
        onClose={() => setIsOpen(false)}
        onImportOverviewDownload={onImportOverviewDownload}
        defaultType="bookings"
        importViewType="bookings"
      />
    </>
  );
}

const importErrorTitle = 'Datei konnte nicht importiert werden';

function WarningMessages({
  info,
  message,
}: {
  info?: InfoContractVoucherBatched[];
  message?: string;
}) {
  if (message) {
    return <p>{message}</p>;
  }

  if (info) {
    const infoOrderedByContracts = info.reduce(
      (acc, curr) => {
        const contractInfo = acc[curr.contractLabel];

        return {
          ...acc,
          [curr.contractLabel]: {
            ...contractInfo,
            ...(curr.contractStartDate && {
              contractStartDate: {
                ...contractInfo?.contractStartDate,
                date: curr.contractStartDate,
                bookingDates: [
                  ...(contractInfo?.contractStartDate?.bookingDates ?? []),
                  curr.contractStartDate && curr.bookingDate,
                ],
              },
            }),
            ...(curr.contractEndDate && {
              contractEndDate: {
                ...contractInfo?.contractEndDate,
                date: curr.contractEndDate,
                bookingDates: [
                  ...(contractInfo?.contractEndDate?.bookingDates ?? []),
                  curr.contractEndDate && curr.bookingDate,
                ],
              },
            }),
          },
        };
      },
      {} as {
        [key: string]: {
          contractStartDate?: { date: string; bookingDates: string[] };
          contractEndDate?: { date: string; bookingDates: string[] };
        };
      },
    );

    return (
      <p>
        {Object.entries(infoOrderedByContracts).map(([currKey, currValue]) => (
          <>
            {currValue.contractStartDate && (
              <>
                <WarningMessage
                  contractLabel={currKey}
                  contractStartDate={currValue.contractStartDate.date}
                  bookingDates={currValue.contractStartDate.bookingDates}
                />
                <br />
                <br />
              </>
            )}

            {currValue?.contractEndDate?.date && (
              <>
                <WarningMessage
                  contractLabel={currKey}
                  contractEndDate={currValue.contractEndDate.date}
                  bookingDates={currValue.contractEndDate.bookingDates}
                />
                <br />
                <br />
              </>
            )}
          </>
        ))}
        Bei Fragen, kann dir das Service Team helfen.
      </p>
    );
  }

  return <></>;
}

function WarningMessage({
  contractLabel,
  contractEndDate,
  contractStartDate,
  bookingDates,
}: {
  contractLabel: string;
  bookingDates: string[];
  contractStartDate?: string;
  contractEndDate?: string;
}) {
  const bookingDatesString = [
    ...new Set(bookingDates.map((date) => formatDate(date))),
  ].join(', ');
  const isSingular = bookingDates.length === 1;
  if (contractStartDate) {
    return (
      <>
        Der Vertrag <b>{contractLabel}</b> beginnt am{' '}
        <b>{formatDate(contractStartDate)}</b>, damit die{' '}
        {isSingular ? 'Buchung' : 'Buchungen'} mit dem Buchungsdatum{' '}
        <b>{bookingDatesString}</b> bei der Rechnungserstellung berücksichtigt
        werden {isSingular ? 'kann' : 'können'}, wurde das Buchungsdatum auf den
        Vertragsbeginn angepasst.
      </>
    );
  }

  if (contractEndDate) {
    return (
      <>
        Der Vertrag <b>{contractLabel}</b> endet am{' '}
        <b>{formatDate(contractEndDate)}</b>, damit die{' '}
        {isSingular ? 'Buchung' : 'Buchungen'} mit dem Buchungsdatum{' '}
        <b>{bookingDatesString}</b> bei der Rechnungserstellung berücksichtigt
        werden {isSingular ? 'kann' : 'können'}, wurde das Buchungsdatum auf das
        Vertragsende angepasst.
      </>
    );
  }

  return <></>;
}
