import set from 'lodash.set';

import { getInputTypeForType } from './mapper';
import { buildYupValidationChain } from './validation';
import { buildMutationReturnTypeRecursive } from './graphl-helper';

export const getMutationType = (schema: any, name: string) => {
  return getMutations(schema).find((field: any) => field.name === name);
};

export const getMutations = (schema: any) => {
  return getType(
    schema,
    (type: any) => type.kind === 'OBJECT' && type.name === 'Mutation',
  ).fields;
};

export const getType = (schema: any, predicate: (t: any) => boolean) => {
  const { types } = schema;
  return types.find(predicate);
};

export const findNestedInputTypes = (schema: any, mutation: any) => {
  const typeNames: Set<string> = new Set();

  visitTree(schema, mutation.args, {
    visitObject: (field: any) => {
      if (field.type.kind === 'INPUT_OBJECT') {
        typeNames.add(field.type.name);
      }
    },
  });

  return typeNames;
};

export const buildArgumentType = (a: any) => {
  if (a.type.kind === 'NON_NULL') {
    return `${a.type.ofType.name}!`;
  }
  throw new Error('Please Implement');
};

export const buildArguments = (args: any) => {
  return args.map((a: any) => `$${a.name}: ${buildArgumentType(a)}`).join(',');
};

/**
 * If a given type is non-nullable, this strips the non-nullability and returns the underlying type.
 */
export const unwrapNonNull = (field: Field) => {
  if (field.type.kind === 'NON_NULL') {
    return field.type.ofType;
  }

  return field.type;
};

export interface Field {
  defaultValue: any;
  description: string;
  name: string;
  type: FieldType;

  /**
   * non standard field we added
   */
  nonNull: boolean;
}

export interface FieldType {
  kind: 'SCALAR' | 'LIST' | 'NON_NULL' | 'OBJECT' | 'INPUT_OBJECT' | 'ENUM';
  name?: string;
  ofType?: FieldType;
}

interface Visitors {
  visitField?(item: any, path: string[], parent: any): void;
  visitList?(item: any, path: string[], parent: any): void;
  visitObject?(item: any, path: string[], parent: any): void;
}

export const visitTree = (
  schema: any,
  list: any[],
  visitors: Visitors,
  path: string[] = [],
  parent?: any,
) => {
  list.forEach((item: any) => {
    const pointer = {
      ...item,
      nonNull: item.type.kind === 'NON_NULL',
      type: unwrapNonNull(item),
    };

    /**
     * Support for arrays of type
     * [PlantInput]
     */
    if (pointer.type.kind === 'LIST') {
      let listOfType = pointer.type.ofType;
      let nonNull = false;

      /**
       * items in the array are NON_NULL like
       * [PlantInput!]!
       * so lets unwrap
       */
      if (listOfType.kind === 'NON_NULL') {
        listOfType = listOfType.ofType;
        nonNull = true;
      }

      if (visitors.visitList) {
        visitors.visitList(
          {
            ...pointer,
            type: listOfType,
            nonNull,
          },
          path,
          parent,
        );
      }

      if (listOfType.kind === 'INPUT_OBJECT') {
        const type = getType(
          schema,
          (t: any) => t.name === listOfType.name && t.kind === 'INPUT_OBJECT',
        );

        const withResolvedType = {
          ...pointer,
          list: true,
          type,
          nonNull,
        };

        if (visitors.visitObject) {
          visitors.visitObject(withResolvedType, path, parent);
        }
        return visitTree(
          schema,
          type.inputFields,
          visitors,
          [...path, item.name, '[0]'],
          withResolvedType,
        );
      }
    } else if (pointer.type.kind === 'INPUT_OBJECT') {
      const type = getType(
        schema,
        (t: any) => t.name === pointer.type.name && t.kind === 'INPUT_OBJECT',
      );

      const withResolvedType = {
        type,
        nonNull: pointer.nonNull,
      };

      if (visitors.visitObject) {
        visitors.visitObject(withResolvedType, path, parent);
      }

      return visitTree(
        schema,
        type.inputFields,
        visitors,
        [...path, item.name],
        withResolvedType,
      );
    }

    if (visitors.visitField) {
      visitors.visitField(pointer, path, parent);
    }
  });
};

export const buildMutationDocument = (
  mutationName: any,
  schema: any,
  readDocument?: any,
  readDocumentFields?: string[],
) => {
  const mutation = getMutationType(schema, mutationName);
  const returnTypeDef =
    mutation.type.kind === 'NON_NULL' ? mutation.type.ofType : mutation.type;
  const returnType = getType(
    schema,
    (t: any) => t.name === returnTypeDef.name && t.kind === returnTypeDef.kind,
  );

  if (!returnType) {
    throw new Error('No ReturnType found');
  }

  const inputArg = mutation.args.find(
    (a: any) =>
      a.type.kind === 'INPUT_OBJECT' ||
      (a.type.kind === 'NON_NULL' && a.type.ofType.kind === 'INPUT_OBJECT'),
  );

  if (!inputArg) {
    throw new Error('No Input Object Name found.');
  }
  const addErrorType = (_returnType: string, returnSubtypes: string) => `
    ... on ${_returnType} {
    ${returnSubtypes} 
    } 
    ... on ValidationErrors {
      __typename
      errors {
        __typename
        message
        pointer
        retryable
      }
    }
  `;

  const addExtraErrorTypes = (returnTypeSource: string): string => {
    const extraErrorTypes = [
      {
        IbanExistsError: `... on IbanExistsError {
          message
          accountHolderId
          accountNumber
          accountHolder
          hasNewMeterBeenAdded
          payers {
            contractNumber
            payerName
          }
      }`,
      },
    ];

    const extraErrorTypesString = extraErrorTypes.reduce((acc, error) => {
      const errorTypeName = Object.keys(error)[0];
      const errorTypeValue = Object.values(error)[0];

      const hasError = returnType.possibleTypes.some(
        (type: any) => type.name === errorTypeName,
      );

      if (hasError) {
        return `${acc}
        ${errorTypeValue}
        `;
      }
      return acc;
    }, '');

    return `
    ${returnTypeSource}
    ${extraErrorTypesString}
    `;
  };

  let returnTypeSource = 'id';
  if (returnType.kind === 'UNION') {
    const nonErrorReturnType = returnType.possibleTypes.find(
      (t: any) => t.name !== 'ValidationErrors',
    );

    returnTypeSource = addErrorType(nonErrorReturnType.name, 'id');
    if (readDocument) {
      returnTypeSource = addErrorType(
        nonErrorReturnType.name,
        buildMutationReturnTypeRecursive(
          readDocument.definitions[0].selectionSet.selections[0].selectionSet,
        ),
      );
      returnTypeSource = addExtraErrorTypes(returnTypeSource);
    } else if (readDocumentFields) {
      returnTypeSource = addErrorType(
        nonErrorReturnType.name,
        readDocumentFields.join('\n'),
      );
    }
  } else if (readDocument && returnType.kind !== 'UNION') {
    returnTypeSource = buildMutationReturnTypeRecursive(
      readDocument.definitions[0].selectionSet.selections[0].selectionSet,
    );
  } else if (readDocumentFields && returnType.kind !== 'UNION') {
    returnTypeSource = readDocumentFields.join('\n');
  }

  const mutationSourceString = `mutation ${mutation.name}(${buildArguments(
    mutation.args,
  )}) {
    ${mutation.name}(${mutation.args
      .map((a: any) => `${a.name}: $${a.name}`)
      .join(',')}) {
      ${returnTypeSource}
    }
  }`;

  return { mutationSourceString, inputObjectName: inputArg.name };
};

interface Annotation {
  type: string;
  fields: any;
  relation?: string;
}

interface FieldProps {
  [key: string]: any;
}

const buildFieldSchema = (
  field: Field,
  path: string,
  parent: any,
  annotations: Annotation[],
): {
  props: FieldProps;
  validation?: any;
  initialValue: any;
  defaultValue: any;
} => {
  let fieldAnnotation;
  let initialValue = null;
  let defaultValue = null;

  const parentAnnotation = annotations.find(
    (a: Annotation) => a.type === parent.type.name,
  );

  if (parentAnnotation && parentAnnotation.fields[field.name]) {
    fieldAnnotation = parentAnnotation.fields[field.name];
  }

  const fieldPath = [path, field.name].filter((i) => i).join('.');

  const { isList, isNonNull, type } = getTypeInformation(field);

  const props = {
    name: fieldPath,
    id: fieldPath,
    label: field.name,
    /**
     * this is probably the better solution
     * but we disable this for now because we
     * don't want the browser to validate the form
     */
    // required: field.nonNull,
    'aria-required': isNonNull,
    type: getInputTypeForType(type),
    dataType: type,
    path,
    ...(fieldAnnotation && fieldAnnotation.annotations),
  };

  props.placeholder = props.label;

  if (fieldAnnotation?.annotations?.default) {
    defaultValue = fieldAnnotation.annotations.default;
  }

  initialValue = '';

  if (props.dataType === 'Boolean') {
    defaultValue = false;
    // props.required = false;
    props['aria-required'] = isNonNull;
  }

  if (fieldAnnotation?.annotations?.asType) {
    props.type = getInputTypeForType(fieldAnnotation.annotations.asType);
  }

  /**
   * the following trys to build a yup validation out of the
   * annotations. yup has a DSL like `yup.string().required()`
   * to build the validation
   */
  const validation = buildYupValidationChain(
    props,
    isList,
    fieldAnnotation?.validations,
  );

  return {
    props,
    validation,
    initialValue,
    defaultValue,
  };
};

export const buildSchema = (
  mutation: any,
  schema: any,
  annotations: Annotation[],
) => {
  const fields: string[] = [];
  const fieldProps = new Map();
  const initialValues: { [key: string]: any } = {};
  const defaultValues: { [key: string]: any } = {};
  const validationSchema: any = {};
  // const requiredPaths: string[] = [];

  visitTree(schema, mutation.args, {
    visitField: (field: any, path: string[], parent: any) => {
      if (!parent) return;

      const pathWithoutParent = path.slice(1);

      const { props, validation, initialValue, defaultValue } =
        buildFieldSchema(
          field,
          pathWithoutParent.join('.'),
          parent,
          annotations,
        );

      fields.push(props.name);
      fieldProps.set(props.name, props);

      const pathWithList = [...pathWithoutParent, field.name].map((s) =>
        s.replace(/\[(.+?)\]/g, (match: string, p1: string) => {
          return p1;
        }),
      );

      set(initialValues, pathWithList, initialValue);

      if (defaultValue !== null) {
        set(defaultValues, pathWithList, defaultValue);
      }

      if (validation !== null) {
        set(validationSchema, pathWithList, validation);
      }
    },
  });

  return {
    validationSchema,
    fieldProps,
    initialValues,
    defaultValues,
    fields,
  };
};

interface TypeInformation {
  isList: boolean;
  isNonNull: boolean;
  type: string;
}

/**
 * resolves a complex type down to its base type
 */
const getTypeInformation = (field: Field): TypeInformation => {
  const resolveType = (type: FieldType): any => {
    switch (type.kind) {
      case 'SCALAR':
        return { type: type.name! };
      case 'LIST':
        return {
          ...resolveType(type.ofType!),
          isList: true,
        };
      case 'NON_NULL':
        return {
          ...resolveType(type.ofType!),
          isNonNull: true,
        };
      case 'ENUM':
        return { type: 'Enum' };
      default:
        throw new Error(`Unsupported type: ${type.kind}`);
    }
  };

  return {
    isNonNull: field.nonNull,
    ...resolveType(field.type),
  } as TypeInformation;
};
