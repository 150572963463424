import React, { useCallback } from 'react';
import * as yup from 'yup';

import GraphqlForm from '../../components/graphql-form';
import CreateFlow, { EditContainerProps } from '../../components/createFlow';
import { ReadPlantMetersDocument } from '../../graphql-types';
import { isValidMeterNumber } from '../../helpers/validateStrings';

import { CreateMeterForm } from './meter/CreateMeterForm';

export function AddMeterContainer({
  onDone,
  existingMeterNumbers,
  plantId,
}: {
  onDone: () => void;
  existingMeterNumbers: string[];
  plantId: string;
}) {
  const Form = useCallback(
    (props: EditContainerProps) => (
      <GraphqlForm
        {...props}
        mutation="createMeter"
        startInEdit
        refetchQueries={[
          {
            query: ReadPlantMetersDocument,
            variables: {
              plantId,
              type: 'customer',
            },
          },
        ]}
        variables={{
          plantId,
        }}
        defaultValues={{
          metering: 'SLP',
        }}
        validation={{
          meterNumber: yup
            .mixed()
            .test(
              'meterNumber name validity',
              'Ungültiges Format',
              isValidMeterNumber,
            )
            .notOneOf(
              existingMeterNumbers,
              'Die Zählernummer wurde bereits bei einem anderen Zähler vergeben.',
            )
            .required(),
          meterPlace: yup.string().required(),
          meterType: yup.string().required(),
          converterFactor: yup.number().min(1).required(),
          metering: yup.string().required(),
        }}
      >
        <CreateMeterForm meteringDisabled />
      </GraphqlForm>
    ),
    [existingMeterNumbers, plantId],
  );

  return <CreateFlow editContainer={Form} kind="Zähler" onDone={onDone} />;
}
