import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { get } from 'lodash';
import { DeepExtractType } from 'ts-deep-extract-types';
import {
  defaultTheme,
  Bold,
  Icons,
} from '@ampeersenergy/ampeers-ui-components';

import {
  useReadMeterSimpleQuery,
  useReadUnboundMetersByPlantQuery,
  ReadUnboundMetersByPlantQuery,
} from '../../../graphql-types';
import { formatMeter } from '../../../helpers/formatStrings';
import { FormikAutoComplete, ItemValue } from '../../autoComplete';
import Relation from '../../relation';
import { ErrorBox } from '../../input';
import { RelationSelectProps } from '../../relationSelect';
import { HintWarning } from '../../hint';

const { Meter, MeterAvailable, MeterUnavailable } = Icons;

export type MeterItem = DeepExtractType<
  ReadUnboundMetersByPlantQuery,
  ['readMeters']
>[0] & {
  disabled?: boolean;
};

type MeterBind = DeepExtractType<MeterItem, ['binds']>[0];

const NOT_AVAILABLE_ERROR = 'Zähler im gewählten Zeitraum nicht verfügbar';

const MeterItemValue = styled(ItemValue)<{ $disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  > :not([hidden]) ~ :not([hidden]) {
    margin-left: 0.5rem;
  }

  color: ${(props) =>
    props.$disabled
      ? props.theme.palette.gray[600]
      : props.theme.palette.gray[800]};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
`;

const formatMeterContractLabels = (binds?: MeterBind[] | null) => {
  if (!binds || binds.length === 0) return null;

  const contractLabels = binds
    .map((bind) => bind?.contractLabel)
    .filter((b) => !!b);

  return `${
    contractLabels.length > 1 ? 'Verträgen' : 'Vertrag'
  } ${contractLabels.join(', ')} zugeordnet`;
};

interface MeterSuggestionProps {
  meter: MeterItem;
}

function MeterSuggestion({ meter }: MeterSuggestionProps) {
  if (meter.isBoundInInterval) {
    const contractLabels = formatMeterContractLabels(meter.binds);

    return (
      <MeterItemValue onClick={(e) => e.stopPropagation()} $disabled>
        <MeterUnavailable size={25} color={defaultTheme.palette.textSubtle} />
        <Bold>{meter.meterNumber}</Bold>
        <span>nicht verfügbar</span>
        {contractLabels ? (
          <>
            <span>·</span>
            <span>{contractLabels}</span>
          </>
        ) : null}
      </MeterItemValue>
    );
  }

  return (
    <MeterItemValue>
      <MeterAvailable size={25} color={defaultTheme.primaryColor} />
      <Bold>{meter.meterNumber}</Bold>
      <span>verfügbar</span>
    </MeterItemValue>
  );
}

interface BooleanState {
  value: boolean;
  setValue: (value: boolean) => void;
}

interface MeterSelectProps extends RelationSelectProps {
  plantId: string;
  boundStartAt: string;
  boundEndAt?: string;
  meterValidationState?: [BooleanState['value'], BooleanState['setValue']];
  warning?: string;
  onSelect?: (meter: MeterItem) => void;
}

function MeterSelect({
  plantId,
  boundStartAt,
  boundEndAt,
  meterValidationState,
  warning,
  name,
  formVariables,
  onSelect,
  ...rest
}: MeterSelectProps) {
  const {
    setFieldError,
    setFieldValue,
    values,
    errors,
    isValid,
    status,
    setStatus,
  } = useFormikContext();
  const selectedMeterId = get(values, name);

  const { data, error, loading } = useReadUnboundMetersByPlantQuery({
    fetchPolicy: 'network-only',
    skip: rest?.disabled === true,
    variables: {
      plantId,
      ...(boundStartAt && { boundStartAt }),
      ...(boundEndAt && { boundEndAt }),
    },
  });

  const { data: meterData } = useReadMeterSimpleQuery({
    variables: {
      id: selectedMeterId,
    },
    skip: selectedMeterId === '' || selectedMeterId === undefined,
  });

  const backendErrorMessage = useMemo(() => {
    if (status && Object.prototype.hasOwnProperty.call(status, name)) {
      return status[name];
    }
    return '';
  }, [status, name]);
  const removeBackendErrorOnClick = useCallback(() => {
    if (status && Object.prototype.hasOwnProperty.call(status, name)) {
      const { [name]: newName, ...statusWithoutMeterError } = status;
      setStatus({ ...statusWithoutMeterError });
    }
  }, [status, setStatus, name]);
  const fieldError = get(errors, name, false);

  useEffect(() => {
    const hasValue =
      selectedMeterId !== undefined &&
      selectedMeterId !== null &&
      selectedMeterId !== '';

    if (!hasValue || (!data?.readMeters && !meterData && !loading)) {
      return;
    }

    /**
     * check if current selected meter is unbound
     * for the given time by doing a lookup in the
     * returned meters
     */

    /**
     * (re)-set the field error every time the current `fieldError`
     * error differs from the calculated `nextError` because
     * formik does reset the errors while validating the form
     * on a global level
     */
    const meter = data?.readMeters.find((m) => m.id === selectedMeterId);
    const nextError = meter ? '' : NOT_AVAILABLE_ERROR;

    // disable submit button when loading or when there is an error
    if (
      meterValidationState !== undefined &&
      loading !== meterValidationState[0]
    ) {
      meterValidationState[1](loading);
    }
    if (Boolean(nextError) && !fieldError && !loading) {
      setFieldError(name, nextError);
    } else if (
      typeof fieldError === typeof nextError &&
      fieldError.toString() !== nextError &&
      isValid
    ) {
      setFieldValue(name, selectedMeterId, true);
    }
  }, [
    loading,
    name,
    meterValidationState,
    setFieldError,
    fieldError,
    data,
    selectedMeterId,
    errors,
    setFieldValue,
    meterData,
    isValid,
  ]);

  /*
   * The suggestions are filtered and sorted here in the following order:
   * - sort by isBoundInInterval to show unbound meters first and bound meters second
   * - sort meter binds by validityEnd
   * - we also add a disabled flag in the end so that the autosuggest knows how to handle the item (disable/enable click handler)
   */
  const suggestions: MeterItem[] = useMemo(() => {
    if (!data?.readMeters) return [];
    return [...(data?.readMeters ?? [])]
      .sort((a, b) =>
        a.isBoundInInterval === b.isBoundInInterval
          ? 0
          : a.isBoundInInterval
            ? 1
            : -1,
      )
      .map((m) => ({
        ...m,
        disabled: !!m.isBoundInInterval,
      }));
  }, [data?.readMeters]);

  const renderSuggestion = useCallback(
    (meter: MeterItem) => <MeterSuggestion meter={meter} />,
    [],
  );

  return (
    <div
      onClick={removeBackendErrorOnClick}
      role="button"
      tabIndex={0}
      onKeyPress={removeBackendErrorOnClick}
    >
      <FormikAutoComplete
        {...rest}
        name={name}
        suggestions={suggestions}
        loading={loading && !data}
        error={error ? `Fehler beim Laden der ${rest.label} Auswahl` : null}
        renderSuggestion={renderSuggestion}
        formatSuggestion={(suggestion) => formatMeter(suggestion)}
        getDataFromSuggestion={(suggestion) => suggestion.id}
        selectedSuggestion={meterData?.readMeter as any}
        formatSelected={(suggestion) => (
          <Relation
            icon={() => <Meter color={defaultTheme.primaryColor} size={20} />}
            title={formatMeter(suggestion)}
          />
        )}
        appendix={<Meter color={defaultTheme.primaryColor} size={20} />}
        placeholder={rest?.placeholder ?? `${rest.label} wählen`}
        onChange={onSelect}
      />
      {warning && <HintWarning>{warning}</HintWarning>}
      {(fieldError || backendErrorMessage) && (
        <ErrorBox>
          {[fieldError, backendErrorMessage].filter((e) => e).join()}
        </ErrorBox>
      )}
    </div>
  );
}
export default MeterSelect;
