import gql from 'graphql-tag';

export default gql`
  mutation createContractsBatched(
    $contracts: [CreateContractsBatchedInput!]!
    $ignoreWarnings: Boolean
  ) {
    createContractsBatched(
      contracts: $contracts
      ignoreWarnings: $ignoreWarnings
    ) {
      __typename
      ... on ContractsBatchedSuccess {
        jobId
        jobName
        status
        progress
        total
      }
      ... on CreateContractsBatchedError {
        failedContracts {
          customerLabel
          contractLabel
          message
          error
        }
        message
        warnings {
          customerLabel
          contractLabel
          message
        }
      }
    }
  }
`;
