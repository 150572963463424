// highlightMatch.tsx
import React from 'react';
import styled from 'styled-components';

const Highlight = styled.span`
  background-color: rgba(67, 160, 71, 0.1);
  font-weight: 600;
`;

interface HighlightMatchesProps {
  text: string;
  matches: Array<{ start: number; end: number }>;
}

/**
 * A React component that highlights specified portions of text.
 *
 * Renders text with highlighted sections based on start and end positions.
 * The component splits the text into segments and applies highlighting
 * to matched sections while preserving the original text formatting.
 *
 * @component
 * @param props.text - The complete text to be displayed
 * @param props.matches - Array of match positions containing start and end indices
 *                       Each match defines a section of text to be highlighted
 *
 * @example
 * ```tsx
 * // Simple highlight
 * <HighlightMatches
 *   text="Hello World"
 *   matches={[{ start: 0, end: 5 }]} // Highlights "Hello"
 * />
 *
 * // Multiple highlights
 * <HighlightMatches
 *   text="The quick brown fox"
 *   matches={[
 *     { start: 4, end: 9 },  // Highlights "quick"
 *     { start: 16, end: 19 } // Highlights "fox"
 *   ]}
 * />
 * ```
 *
 * Styled with:
 * - Light green background (rgba(67, 160, 71, 0.1))
 * - Semi-bold text
 * - Preserves whitespace and line breaks
 */
export const HighlightMatches: React.FC<HighlightMatchesProps> = ({
  text,
  matches,
}) => {
  if (!matches.length) return <>{text}</>;

  let lastIndex = 0;
  const elements: JSX.Element[] = [];

  matches.forEach((match, idx) => {
    // Add non-matching text
    if (match.start > lastIndex) {
      elements.push(
        <span key={`text-${idx}`}>{text.slice(lastIndex, match.start)}</span>,
      );
    }

    // Add highlighted matching text
    elements.push(
      <Highlight key={`highlight-${idx}`}>
        {text.slice(match.start, match.end)}
      </Highlight>,
    );

    lastIndex = match.end;
  });

  // Add any remaining text
  if (lastIndex < text.length) {
    elements.push(<span key="text-end">{text.slice(lastIndex)}</span>);
  }

  const Wrapper = styled.div`
    white-space: pre-wrap;
  `;

  return <Wrapper>{elements}</Wrapper>;
};
