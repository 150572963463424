import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Field, connect, FieldArray } from 'formik';
import { Button } from '@ampeersenergy/ampeers-ui-components';
import { DateTime } from 'luxon';
import get from 'lodash.get';

import { FlexRow, Flex } from './layout';
import Label from './label';
import { Hint, HintWarning } from './hint';
import ExtendedDropdown, { ExtendedDropdownProps } from './ExtendedDropdown';
import { TooltipInfo } from './TooltipInfo';

export type InputProps<T> = React.InputHTMLAttributes<HTMLInputElement> &
  T & {
    id: string;
    label?: string;
    errorMsg?: React.ReactNode;
    touched?: boolean;
    hint?: React.ReactNode;
    warning?: React.ReactNode;
    appendix?: React.ReactNode;
    prependix?: React.ReactNode;
    unit?: string;
    'data-testid'?: string;
    key?: string;
    inputClassName?: string;
    tooltipText?: string;
  };

export const FormElementStyle = styled.div`
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 15px;

  .input-group {
    border: 1px solid #f5f5f5;
    background: #f5f5f5;
    border-radius: 4px;

    .prependix,
    .appendix {
      padding: 0px 8px 0px 8px;
    }
  }

  input,
  select,
  textarea {
    border: 1px solid #f5f5f5;
    background: #f5f5f5;
    border-radius: 4px;
    font-size: 15px;
    padding: 8px 6px;
    outline: 0;
    flex: 1;
    width: 100%;
    box-shadow: 0px 0px 0px 0px #dcdcdc;
    transition:
      box-shadow 70ms ease-out,
      border-color 70ms ease-out;

    &:disabled {
      color: #888888;
      user-select: none;
      cursor: not-allowed;

      ~ label {
        color: #888888;
      }
    }

    &:focus {
      border-color: #1a6aff;
      box-shadow: 0px 0px 0px 2px #067ffb52;
    }

    &.touched.error {
      color: #e64a19;
      border-color: #e64a19;
    }
  }

  textarea {
    min-height: 200px;
    width: 100%;
  }

  select {
    display: block;
    padding: 5px 6px;
    height: 39.5px;
  }

  .prependix,
  .appendix {
    display: flex;
    white-space: nowrap;
  }

  .prependix {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: 8px;
  }

  .appendix {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding-left: 8px;
  }

  .inline {
    display: inline-block;
    width: initial;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;

  &.with-appendix,
  &.with-prependix {
    input,
    select,
    textarea {
      width: 100%;
    }
  }
`;

export const ErrorBox = styled.div`
  font-size: 14px;
  color: #e64a19;
  margin-top: 5px;
`;

const CheckboxLabel = styled(Label)`
  display: inline-block;
  margin-left: 6px;
`;

export const wrapFormElement = <T,>(Element: any) =>
  React.forwardRef<HTMLInputElement, InputProps<T>>((props, ref) => {
    const {
      label,
      id,
      errorMsg,
      touched,
      hint,
      warning,
      appendix,
      prependix,
      unit,
      className,
      inputClassName,
      tooltipText,
      ...rest
    } = props;
    let { value } = props;
    const inputClassnames = inputClassName ? [inputClassName] : [];
    const groupClassnames = ['input-group'];

    if (touched) {
      inputClassnames.push('touched');
    }

    if (errorMsg) {
      inputClassnames.push('error');
    }

    if (appendix || unit) {
      groupClassnames.push('with-appendix');
    }

    if (prependix) {
      groupClassnames.push('with-prependix');
    }

    /**
     * datetime-local dosent support timezones
     */
    if (
      props.type === 'datetime-local' ||
      props.type === 'date' ||
      props.type === 'Date'
    ) {
      if (value) {
        if (props.type === 'datetime-local') {
          const date = DateTime.fromISO(String(value));
          value = `${date.toFormat('yyyy-MM-dd')}T${date.toFormat('HH:mm')}`;
        } else {
          const date = DateTime.fromISO(String(value), { zone: 'utc' });
          value = `${date.toFormat('yyyy-MM-dd')}`;
        }
      }
    }
    if (props.type === 'checkbox') {
      inputClassnames.push('inline');
      return (
        <FormElementStyle className={className} key={props.id}>
          <Element
            {...rest}
            id={id}
            ref={ref as any}
            className={inputClassnames.join(' ')}
            value={value}
          />
          {label && <CheckboxLabel htmlFor={id}>{label}</CheckboxLabel>}
          {hint && !errorMsg && <Hint>{hint}</Hint>}
          {warning && !errorMsg && <HintWarning>{warning}</HintWarning>}
          {errorMsg && (
            <ErrorBox
              {...(rest['data-testid'] && {
                'data-testid': `${id}-error`,
              })}
            >
              {errorMsg}
            </ErrorBox>
          )}
        </FormElementStyle>
      );
    }

    return (
      <FormElementStyle className={className} key={props.id}>
        {label && (
          <Label htmlFor={id}>
            {label}
            {tooltipText && (
              <TooltipInfo id={`${label}Tooltip`} text={tooltipText} />
            )}
          </Label>
        )}
        <InputGroup className={groupClassnames.join(' ')}>
          {prependix && <div className="prependix">{prependix}</div>}
          <Element
            {...rest}
            id={id}
            ref={ref as any}
            className={inputClassnames.join(' ')}
            value={value}
          />
          {appendix && <div className="appendix">{appendix}</div>}
          {unit && <div className="appendix">{unit}</div>}
        </InputGroup>
        {hint && !errorMsg && <Hint>{hint}</Hint>}
        {warning && !errorMsg && <HintWarning>{warning}</HintWarning>}
        {errorMsg && (
          <ErrorBox
            {...(rest['data-testid'] && {
              'data-testid': `${id}-error`,
            })}
          >
            {errorMsg}
          </ErrorBox>
        )}
      </FormElementStyle>
    );
  });

export const Input = wrapFormElement('input');
export const Select = wrapFormElement('select');
export const Radio = wrapFormElement('div');
export const Textarea = wrapFormElement('textarea');
export const Checkbox = wrapFormElement('checkbox');
export const WrappedDropdown =
  wrapFormElement<ExtendedDropdownProps>(ExtendedDropdown);

const wrapForFormik = (Element: any) => {
  return function FormikWrapper({
    field,
    form: {
      touched,
      errors,
      values,
      setFieldValue,
      isSubmitting,
      submitCount,
      validateOnMount,
      status,
    },
    ...props
  }: {
    field: any;
    form: any;
  }) {
    const inputProps = props as React.HTMLProps<HTMLInputElement>;
    const backendErrorMessage = useMemo(() => {
      if (
        status &&
        Object.prototype.hasOwnProperty.call(status, field.name) &&
        !get(touched, field.name, false)
      ) {
        return status[field.name];
      }
      return '';
    }, [status, touched, field.name]);
    const value = get(values, field.name, '');
    const hasError =
      ((get(touched, field.name, false) ||
        submitCount > 0 ||
        validateOnMount) &&
        get(errors, field.name, false)) ||
      (get(status, field.name, false) && !get(touched, field.name, false));
    const isDisabled = inputProps.disabled;
    return (
      <Element
        {...props}
        {...field}
        {...(inputProps.type !== 'checkbox' && {
          value,
        })}
        {...(inputProps.type === 'checkbox' && {
          checked: value === true,
        })}
        onChange={(e: any) => {
          if (inputProps.onChange) inputProps.onChange(e);
          setFieldValue(
            field.name,
            e.target.type === 'checkbox' ? e.target.checked : e.target.value,
          );
        }}
        errorMsg={
          hasError && !isDisabled && !isSubmitting
            ? `${[get(errors, field.name, ''), backendErrorMessage]
                .filter((e) => e)
                .join(', ')}`
            : null
        }
        touched={hasError || get(touched, field.name)}
        disabled={isDisabled || isSubmitting}
      />
    );
  };
};

const wrapInField = (Element: any) =>
  function wrap(props: any) {
    return <Field {...props} component={Element} />;
  };

/**
 *  The following component only work in a Formik context.
 */
export const FormikInput = wrapInField(wrapForFormik(Input));
export const FormikSelect = wrapInField(wrapForFormik(Select));
export const FormikRadio = wrapInField(wrapForFormik(Radio));
export const FormikTextarea = wrapInField(wrapForFormik(Textarea));
export const FormikDropdown = wrapInField(wrapForFormik(WrappedDropdown));

export const FormikSubmit = connect<React.ComponentProps<typeof Button>>(
  ({ formik: { isSubmitting }, ...props }) => {
    return (
      <Button
        id="submit-btn"
        type="submit"
        isLoading={isSubmitting}
        disabled={isSubmitting}
        {...props}
      />
    );
  },
);

const MultipleItem = styled(FlexRow)`
  border-bottom: 1px solid lightgrey;
`;

interface FormikMultipleProps {
  name: string;
  subFields: string[];
  renderField: any;
  label: string;
}

export const FormikMultiple = connect<FormikMultipleProps>(
  ({ formik, name, subFields, renderField }) => {
    const values = get(formik.values, name);

    return (
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <>
            <Label>{name}</Label>
            <Button
              secondary
              type="button"
              onClick={() => arrayHelpers.push({})}
            >
              +
            </Button>
            <div>
              {(values as any).map((value: any, index: number) => (
                <MultipleItem>
                  {subFields.map((fieldName) => (
                    <Flex>
                      {' '}
                      {renderField(
                        fieldName,
                        index,
                        value,
                        true,
                        // index === values.length - 1
                      )}
                    </Flex>
                  ))}
                  <Button
                    secondary
                    type="button"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    -
                  </Button>
                </MultipleItem>
              ))}
            </div>
          </>
        )}
      />
    );
  },
);
