import { formatBoolean } from '@ampeersenergy/ampeers-ui-components';
import { get } from 'lodash';
import { DateTime } from 'luxon';

import { formatNumber } from '../../../helpers/formatStrings';
import { isObject } from '../../../helpers/utils';
import {
  GraphqlFormFieldIndentifier,
  GraphqlFormFieldInternalIdentifier,
  GraphqlFormFieldProps,
} from '../../graphql-form/hooks/useGraphqlFormField';
import { formatDateToSimpleDateString } from '../../../helpers/dateHelpers';

export function getObjectPath<T>(obj: T, prefix?: string): string[] {
  if (Array.isArray(obj)) {
    return [];
  }
  if (isObject(obj)) {
    const keys = Object.keys(obj);
    const objPrefix = prefix ? `${prefix}.` : '';
    return keys.reduce((result: string[], key) => {
      if (isObject(obj[key])) {
        return [...result, ...getObjectPath(obj[key], objPrefix + key)];
      }
      return [...result, objPrefix + key];
    }, []);
  }

  return [];
}

export function removeEqualValues<T>(
  obj: T,
  compareObj?: any,
  prefix?: string,
  ignoreKeys?: string[],
): Partial<T> {
  if (Array.isArray(obj)) {
    return obj;
  }
  if (isObject(obj)) {
    return Object.keys(obj).reduce((acc, key) => {
      if (obj[key] === undefined) {
        return acc;
      }
      const objPrefix = prefix ? `${prefix}.` : '';

      const val = removeEqualValues(
        obj[key],
        compareObj,
        objPrefix + key,
        ignoreKeys,
      ) as any;
      const compareVal = get(compareObj, `${objPrefix}${key}`);

      if (
        val !== typeof 'string' &&
        val !== typeof 'number' &&
        !Number.isNaN(new Date(val).getDate()) &&
        compareVal !== undefined &&
        DateTime.fromJSDate(new Date(val)).toISODate() ===
          DateTime.fromISO(compareVal).toISODate()
      ) {
        return acc;
      }
      if (
        val !== compareVal ||
        (ignoreKeys && ignoreKeys?.includes(`${objPrefix}${key}`))
      ) {
        return {
          ...acc,
          [key]: val,
        };
      }
      return acc;
    }, {} as T);
  }

  return obj;
}

export function translateLabel(path: string, label?: string) {
  let prefix = '';

  if (path.includes('addressShipping')) prefix = 'Lieferadresse';
  else if (path.includes('addressBilling')) prefix = 'Rechnungsadresse';
  else if (
    path.includes('customer.person') ||
    path.includes('customer.address.')
  )
    prefix = 'Vertragspartner';

  return `${prefix} ${label ?? path}`.trim();
}

export function formatExistingValue(
  path: string,
  value: string | number | boolean,
  fieldProps?: GraphqlFormFieldProps &
    GraphqlFormFieldInternalIdentifier &
    GraphqlFormFieldIndentifier,
) {
  if (typeof value === 'boolean') {
    return formatBoolean(value);
  }
  if (typeof value === 'number') {
    return formatNumber(Number(value));
  }
  if (
    typeof value === 'string' &&
    DateTime.fromISO(value, { zone: 'utc' }).isValid &&
    DateTime.fromISO(value, { zone: 'utc' }).toISO() === value
  ) {
    return formatDateToSimpleDateString(value);
  }
  if (fieldProps && fieldProps.values && fieldProps.labels) {
    return fieldProps.values.indexOf(value) >= 0
      ? (fieldProps.labels[fieldProps.values.indexOf(value)] ?? value)
      : value;
  }
  return value;
}
