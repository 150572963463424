import {
  Button,
  Icons,
  TooltipDark,
  useDialog,
  useToasts,
} from '@ampeersenergy/ampeers-ui-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useApolloClient, useMutation } from '@apollo/client';

import {
  AccountingRunWorkflowState,
  AccountingRunWorkflowStepName,
  OpcStepResult,
  OverviewStepResult,
  StepStatus,
  useDeleteAccountingRunWorkflowMutation,
  useReadInvoiceDraftsQueryQuery,
  useRestartAccountingRunWorkflowMutation,
} from '../../../graphql-types';
import { useHasRole } from '../../../components/useHasRole';

const ModalMessage = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

function sleep(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function DetailsActions({
  workflow,
  workflowId,
}: {
  workflow: AccountingRunWorkflowState;
  workflowId: string;
}) {
  const { name } = workflow;
  const { hasRole: hasOperationsRole } = useHasRole('ae-operations');
  const [deleteWorkflowMutation] = useDeleteAccountingRunWorkflowMutation();

  const [restartAccountingRunMutation] =
    useRestartAccountingRunWorkflowMutation();

  const navigate = useNavigate();
  const toast = useToasts();
  const { openConfirmation } = useDialog();

  const sessionId = getSessionId(workflow);
  const opcStepStarted = hasOpcStepStarted(workflow);

  const deletionAllowed = !!sessionId;
  const opcName = getOpcName(workflow);

  const { data: invoiceDrafts, loading: invoiceDraftsLoading } =
    useReadInvoiceDraftsQueryQuery({
      variables: {
        filterByOPCName: opcName,
        showDocuments: false,
      },
      skip: !opcName || workflow.doneAt,
      fetchPolicy: 'no-cache',
    });

  const hasConfirmedInvoice =
    invoiceDrafts?.drafts.some((invoice) => invoice.confirmed) ?? false;

  const canDeleteWorkflow = deletionAllowed && !hasConfirmedInvoice;
  const canRestartAccountingRun = !workflow.doneAt && !hasConfirmedInvoice;

  const handleDeleteWorkflowModalClick = React.useCallback(async () => {
    try {
      const deleteWorkflowResponse = await deleteWorkflowMutation({
        variables: { workflowId },
      });

      if (deleteWorkflowResponse.data?.deleteAccountingRunWorkflow.success) {
        await sleep(2000);
        toast.success(
          `Der Abrechnungslauf "${name}" wurde erfolgreich gelöscht`,
          { autoClose: 4000 },
        );
        navigate(`/accounting/runs`);
      } else {
        toast.error(
          `Der Abrechnungslauf "${name}" mit der ID ${workflowId} konnte nicht erfolgreich gelöscht werden.`,
        );
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : String(err);
      toast.error(
        `Es gab einen Fehler beim Versuch, den Abrechnungslauf "${name}" mit der ID ${workflowId} zu löschen: ${message}`,
      );
    }
  }, [deleteWorkflowMutation, name, opcName, toast, workflowId, navigate]);

  const handleRestartAccountingRunWorkflow = React.useCallback(async () => {
    try {
      const restartAccountingRunWorkflowResponse =
        await restartAccountingRunMutation({
          variables: { workflowId },
        });

      if (
        restartAccountingRunWorkflowResponse.data?.restartAccountingRunWorkflow
          .success
      ) {
        window.location.reload();
        toast.success(
          `Der Abrechnungslauf "${name}" wurde erfolgreich neu gestartet`,
          {
            autoClose: 4000,
          },
        );
      } else {
        toast.error(
          `Der Abrechnungslauf "${name}" mit der ID ${workflowId} konnte nicht neugestartet werden.`,
        );
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : String(err);
      toast.error(
        `Es gab einen Fehler beim Versuch den Abrechnungslauf "${name}" mit der ID ${workflowId} neuzustarten: ${message}`,
      );
    }
  }, [name, restartAccountingRunMutation, toast, workflowId]);

  const deleteWorkflowModal = React.useCallback(async () => {
    const modalContent = (
      <ModalMessage>
        Sind Sie sicher, dass Sie die Abrechnung
        <br /> <b>&quot;{name}&quot;</b> <br />
        löschen möchten?
      </ModalMessage>
    );

    await openConfirmation({
      confirmButtonLabel: 'Löschen',
      dangerButton: true,
      content: modalContent,
      title: name || '',
      maxWidth: 'md',
      handleRequest: handleDeleteWorkflowModalClick,
    });
  }, [
    handleDeleteWorkflowModalClick,
    hasOperationsRole,
    name,
    opcName,
    openConfirmation,
  ]);

  const restartAccountingRunClick = React.useCallback(async () => {
    const modalContent = (
      <ModalMessage>
        Sind Sie sicher, dass Sie die Abrechnung
        <br /> <b>&quot;{name}&quot;</b> <br />
        neustarten möchten?
      </ModalMessage>
    );

    await openConfirmation({
      confirmButtonLabel: 'Neustarten',
      dangerButton: true,
      content: modalContent,
      title: name || '',
      maxWidth: 'md',
      handleRequest: handleRestartAccountingRunWorkflow,
    });
  }, [
    handleRestartAccountingRunWorkflow,
    hasOperationsRole,
    name,
    opcName,
    openConfirmation,
  ]);

  const disabledTooltipText =
    'Der Rechnungslauf kann nicht gelöscht oder neu gestartet werden, da es mindestens eine freigegebene Rechnung gibt. Um die ungeprüfte Rechnung zu bearbeiten, löschen Sie diese bitte und fügen diese einem neuen Rechnungslauf hinzu. Um freigegebene Rechnungen zu bearbeiten, stornieren Sie diese zunächst.';

  const renderButton = (text: string, onClick?: () => void) => {
    if (!canDeleteWorkflow) {
      return (
        <TooltipDark
          text={disabledTooltipText}
          id="disabled-button"
          maxWidth="300px"
        >
          <Button secondary disabled data-tip data-for="disabled-button">
            {text}
          </Button>
        </TooltipDark>
      );
    }

    return (
      <Button secondary onClick={onClick}>
        {text}
      </Button>
    );
  };

  return (
    <>
      {renderButton('Rechnungslauf löschen', deleteWorkflowModal)}
      {renderButton('Rechnungslauf neu starten', restartAccountingRunClick)}
    </>
  );
}

function getSessionId(workflow: AccountingRunWorkflowState) {
  const results = workflow.steps.map((s) => s.result);

  const overviewStepResult = results.find(
    (r): r is OverviewStepResult => r?.__typename === 'OverviewStepResult',
  );

  return overviewStepResult?.session;
}

function hasOpcStepStarted(workflow: AccountingRunWorkflowState) {
  const opcStepStatus = workflow.steps.find(
    (s) => s.name === AccountingRunWorkflowStepName.OpcStep,
  )?.status;

  return opcStepStatus !== StepStatus.Inactive;
}

function getOpcName(workflow: AccountingRunWorkflowState) {
  const results = workflow.steps.map((s) => s.result);

  const overviewStepResult = results.find(
    (r): r is OpcStepResult => r?.__typename === 'OpcStepResult',
  );

  return overviewStepResult?.name;
}
