import { AnalyticsBrowser } from '@segment/analytics-next';

import { User } from '../graphql-types';

import { _ae_env_ } from './env';

export const loadMSClarityScript = (cookiePresent: boolean) => {
  if (cookiePresent) {
    const script = document.createElement('script');

    script.id = 'ms-clarity';
    script.type = 'text/javascript';
    script.innerHTML = `
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(
      window,
      document,
      "clarity",
      "script",
      "${_ae_env_.REACT_APP_MS_CLARITY_TRACKING}"
    );
  `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }

  const sourceClarityScripts = document.querySelectorAll(
    `script[src*="${'clarity.ms'}"]`,
  );
  sourceClarityScripts.forEach((script) => {
    script.remove();
  });

  const script = document.getElementById('ms-clarity');

  if (script) {
    script.remove();
  }

  if (window.clarity) {
    window.clarity('stop');
  }
};

export const loadPostHogScript = (cookiePresent: boolean) => {
  if (cookiePresent) {
    const script = document.createElement('script');
    script.id = 'posthog';
    script.type = 'text/javascript';
    script.innerHTML = `
      !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.crossOrigin="anonymous",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys getNextSurveyStep".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
      
      // Initialize PostHog with window.analytics when it's available
      const initPostHog = function() {
        if (window.analytics) {
          window.analytics.ready(function() {
            window.posthog.init("${_ae_env_.REACT_APP_POSTHOG_API_KEY || ''}", {
              api_host: "https://eu.i.posthog.com", 
              segment: window.analytics,
              capture_pageview: false,
              loaded: function(posthog) {
                window.analytics.page();
              }
            });
          });
        } else {
          // Retry after a short delay if analytics isn't available yet
          setTimeout(initPostHog, 100);
        }
      };
      
      // Start the initialization process
      initPostHog();
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }

  // Cleanup PostHog if cookie is not present
  const sourcePostHogScripts = document.querySelectorAll(
    `script[src*="${'posthog'}"]`,
  );
  sourcePostHogScripts.forEach((script) => {
    script.remove();
  });

  const script = document.getElementById('posthog');
  if (script) {
    script.remove();
  }

  // Reset PostHog if it exists
  if (window.posthog) {
    window.posthog.reset();
  }
};

export const loadSegmentScript = (cookiePresent: boolean) => {
  if (cookiePresent) {
    const script = document.createElement('script');
    script.id = 'segment';
    script.type = 'text/javascript';
    script.innerHTML = `
      !function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="ladiOyj0Duf96ALq8Q99oy9djEOIxCTi";;analytics.SNIPPET_VERSION="5.2.0";
      analytics.load("${_ae_env_.REACT_APP_SEGMENT_API_KEY}");
      analytics.page();
      }}();
  `;
    document.body.appendChild(script);

    segmentAnalytics = AnalyticsBrowser.load({
      writeKey: _ae_env_.REACT_APP_SEGMENT_API_KEY ?? '',
    });

    return () => {
      document.body.removeChild(script);
    };
  }
};

export const identifySegmentUser = (
  user?: Pick<User, 'id' | 'email' | 'firstName' | 'lastName'>,
) => {
  if (user) {
    segmentAnalytics?.identify(user.id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    });
  } else {
    segmentAnalytics?.identify('.1', {
      name: 'Unidentified User',
      email: '',
    });
  }
};

// eslint-disable-next-line import/no-mutable-exports
export let segmentAnalytics: AnalyticsBrowser | null = null;

export const logErrorToClarity = (key?: string, value?: string | string[]) => {
  if (window.clarity) {
    window.clarity('set', 'error', `${key ?? ''}-${value ?? ''}`);
  }
};
