import { useCallback } from 'react';

import { segmentAnalytics } from './analyticsHelpers';
import { getOptionalCookiesAccepted } from './storageUtils';

/**
 * Custom React Hook to track click and keypress events.
 *
 * @param {string} label - The event name to be used in segment analytics tracking.
 * @param {any} value - The value to be sent as a property with the event name in segment analytics tracking.
 * @param {Function} onClick - Optional callback function to be executed on click or keypress events.
 *
 * @returns {Function} handleEvent - A memoized event handler that tracks the event using segment analytics and calls the optional onClick callback if provided.
 */
const useClickAndKeyPressTracking = (
  label: string,
  entry: { [key: string]: string },
  onClick?: () => void,
) => {
  const handleEvent = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      const isCookieSet = getOptionalCookiesAccepted();
      if (isCookieSet) {
        segmentAnalytics?.track(label, entry);
      }

      if ((event.type === 'click' || event.type === 'keypress') && onClick) {
        onClick();
      }
    },
    [label, entry, onClick],
  );

  return handleEvent;
};

export default useClickAndKeyPressTracking;
